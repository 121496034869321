import React from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import PammDashboardChart from "../PammDashboardChart/PammDashboardChart";

const PammDashboardCard = (props) => {
  return (
    <Card>
      <Card.Header>
        <div style={{ fontSize: "1rem", color: "#366dc7" }}>
          {props.data.name}
        </div>
        <div style={{ fontSize: "0.7rem" }}>ID: {props.data.id}</div>
      </Card.Header>
      <Card.Body className="p-0">
        <Container fluid className="px-3" style={{ fontSize: "0.6rem" }}>
          <Row style={{ maxHeight: "75px" }}>
            <PammDashboardChart data={props.data.id} />
          </Row>
          <Row className="mt-2">
            <Col lg="6" xs="6" style={{ paddingRight: "5px" }}>
              <div className="d-flex flex-row justify-content-between pr-0">
                <div>Perf:</div>
                <div style={{ alignSelf: "end" }}>{props.data.perf}%</div>
              </div>
            </Col>
            <Col lg="6" xs="6" className="pl-1" style={{ paddingLeft: "5px" }}>
              <div className="d-flex flex-row justify-content-between">
                <div>Tot. profit:</div>{" "}
                <div style={{ alignSelf: "end" }}>{props.data.total} USD</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" xs="6" style={{ paddingRight: "5px" }}>
              <div className="d-flex flex-row justify-content-between pr-0">
                <div>Man:</div>
                <div style={{ alignSelf: "end" }}>{props.data.man}%</div>
              </div>
            </Col>
            <Col lg="6" xs="6" className="pl-1" style={{ paddingLeft: "5px" }}>
              <div className="d-flex flex-row justify-content-between">
                <div>Open PnL:</div>{" "}
                <div style={{ alignSelf: "end" }}>{props.data.pnl} USD</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" xs="6" style={{ paddingRight: "5px" }}>
              <div className="d-flex flex-row justify-content-between pr-0">
                <div>Ann. Man:</div>{" "}
                <div style={{ alignSelf: "end" }}>{props.data.ann_man}%</div>
              </div>
            </Col>
            <Col lg="6" xs="6" className="pl-1" style={{ paddingLeft: "5px" }}>
              <div className="d-flex flex-row justify-content-between">
                <div>Min. inv:</div>{" "}
                <div style={{ alignSelf: "end" }}>{props.data.min_inv} USD</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" xs="6" style={{ paddingRight: "5px" }}>
              <div className="d-flex flex-row justify-content-between pr-0">
                <div>Per Lot:</div>{" "}
                <div style={{ alignSelf: "end" }}>{props.data.per_lot} USD</div>
              </div>
            </Col>
            <Col lg="6" xs="6" className="pl-1" style={{ paddingLeft: "5px" }}>
              <div className="d-flex flex-row justify-content-between">
                <div>Investors:</div>{" "}
                <div style={{ alignSelf: "end" }}>{props.data.investors}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6" xs="6" style={{ paddingRight: "5px" }}>
              <div className="d-flex flex-row justify-content-between pr-0">
                <div>Per Deal</div>{" "}
                <div style={{ alignSelf: "end" }}>
                  {props.data.per_deal} USD
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Button
                className="mt-3 mb-2"
                style={{ width: "100%", fontSize: "0.8rem" }}
              >
                Connect
              </Button>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default PammDashboardCard;
