import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Image } from "react-bootstrap";

import CastleLogo from "../../assets/img/Logo_Castle_Rock.png";
import SignUp from "../../components/auth/SignUp";

const SignUpPage = () => (
  <React.Fragment>
    <Helmet title="Sign Up" />
    <div className="text-center mt-4">
      <Image src={CastleLogo} fluid />
      <br />
    </div>
    <div className="text-center mt-4">
      <h1 className="h1">Registration details</h1>
      <p className="lead"></p>
    </div>

    <Card>
      <Card.Body>
        <div className="m-sm-4">
          <SignUp />
        </div>
      </Card.Body>
    </Card>
  </React.Fragment>
);

export default SignUpPage;
