import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";

import useAuth from "../../../../hooks/useAuth";
import { ButtonGroup, Card, Button, ToggleButton } from "react-bootstrap";
import { getOrdersCloses } from "../../../../utils/ApiPamm";

import usePalette from "../../../../hooks/usePalette";
import styles from "./ProfitLineChart.module.css";
import moment from "moment";

const ProfitLineChart = ({ ordersClosesData }) => {
  const palette = usePalette();
  const [profitsData, setProfitsData] = useState([]);
  const [profitLabels, setProfitLabels] = useState([]);
  const [filterDaysUntilToday, setFilterDaysUntilToday] = useState(-1);
  const { user } = useAuth();

  useEffect(() => {
    console.log("filterDaysUntilNowWork");
    if (ordersClosesData) setupDataAndLabels(ordersClosesData);
  }, [filterDaysUntilToday]);

  useEffect(() => {
    if (ordersClosesData) setupDataAndLabels(ordersClosesData);
  }, [ordersClosesData]);

  function setupProfits() {
    getOrdersCloses(user.id).then((res) => {
      setupDataAndLabels(res.data.Data);
    });
  }

  function setupDataAndLabels(ordersCloseData) {
    let filteredData = filterClosesData(ordersCloseData, filterDaysUntilToday);
    console.log({ filteredData });

    const ordersCloseDataDateFormatted = filteredData
      .sort((a, b) => a.CloseTime > b.CloseTime)
      .map((item) => {
        return {
          ...item,
          CloseTime: moment(new Date(item.CloseTime)).format("DDMMM'YY"),
        };
      });

    let result = groupBy(ordersCloseDataDateFormatted, "CloseTime");

    const profitsData = Object.values(result).map((el) => {
      return el.reduce(function (sum, current) {
        return Number((sum + current.Profit).toFixed(2));
      }, 0);
    });
    const labelsData = Object.keys(result).map((el) => {
      return el;
    });
    setProfitsData(profitsData);
    setProfitLabels(labelsData);
  }

  function filterClosesData(ordersCloseData, filterDaysUntilNow) {
    let filteredClosesData = ordersCloseData;
    let dateTillFilter = subtractDays(filterDaysUntilNow);
    if (filterDaysUntilNow === -1) {
      filteredClosesData = filteredClosesData;
    } else {
      filteredClosesData = filteredClosesData.filter((data) => {
        return (
          moment(data.CloseTime).format("YYYY-MM-DD") >
          moment(dateTillFilter).format("YYYY-MM-DD")
        );
      });
    }
    return filteredClosesData;
  }

  function subtractDays(numOfDays, date = new Date()) {
    date.setDate(date.getDate() - numOfDays);

    return date;
  }

  function changeFilterDaysUntilNow(filterDaysUntilNow) {
    setFilterDaysUntilToday(filterDaysUntilNow);
  }

  function changeFilterMonth() {
    const currentDay = new Date();
    const year = currentDay.getFullYear();
    const month = currentDay.getMonth();
    const firstDayOfMonth = new Date(year, month, 1);
    const msDiff = currentDay - firstDayOfMonth;
    const days = convertMiliseconds(msDiff, "d");
    console.log("Days: ", days);
    setFilterDaysUntilToday(days);
  }

  function changeFilterYear() {
    const currentDay = new Date();
    const year = currentDay.getFullYear();
    const firstDayOfYear = new Date(year, 0, 1);
    const msDiff = currentDay - firstDayOfYear;
    const days = convertMiliseconds(msDiff, "d");
    console.log("Days: ", days);
    setFilterDaysUntilToday(days);
  }

  function convertMiliseconds(miliseconds, format) {
    var days,
      hours,
      minutes,
      seconds,
      total_hours,
      total_minutes,
      total_seconds;

    total_seconds = parseInt(Math.floor(miliseconds / 1000));
    total_minutes = parseInt(Math.floor(total_seconds / 60));
    total_hours = parseInt(Math.floor(total_minutes / 60));
    days = parseInt(Math.floor(total_hours / 24));

    seconds = parseInt(total_seconds % 60);
    minutes = parseInt(total_minutes % 60);
    hours = parseInt(total_hours % 24);

    switch (format) {
      case "s":
        return total_seconds;
      case "m":
        return total_minutes;
      case "h":
        return total_hours;
      case "d":
        return days;
      default:
        return { d: days, h: hours, m: minutes, s: seconds };
    }
  }

  function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  const data = {
    labels: profitLabels.length > 0 ? profitLabels : [""],
    datasets: [
      {
        label: "profit",
        fill: true,
        backgroundColor: "transparent",
        borderColor: palette.primary,
        data: profitsData.length > 0 ? profitsData : [0],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
    },
    hover: {
      intersect: true,
    },
    plugins: {
      filler: {
        propagate: false,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.05)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 500,
            // beginAtZero: true,
          },
          display: true,
          borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff",
            zeroLineColor:
              profitsData.length > 0 ? "rgba(0,0,0,0.05)" : palette.primary,
          },
        },
      ],
    },
  };

  return (
    <Card>
      <Card.Header>
        <div className={styles.titleChart}>
          <Card.Title tag="h5">Total Profit</Card.Title>
          <ButtonGroup>
            <ToggleButton
              type="radio"
              variant="secondary"
              bsPrefix={styles.radioFilterButton}
              checked={filterDaysUntilToday === 1}
              onClick={() => changeFilterDaysUntilNow(1)}
            >
              DAY
            </ToggleButton>
            <ToggleButton
              type="radio"
              variant="secondary"
              bsPrefix={styles.radioFilterButton}
              checked={filterDaysUntilToday === 7}
              onClick={() => changeFilterDaysUntilNow(7)}
            >
              WEEK
            </ToggleButton>
            <ToggleButton
              type="radio"
              variant="secondary"
              bsPrefix={styles.radioFilterButton}
              checked={filterDaysUntilToday === 14}
              onClick={() => changeFilterDaysUntilNow(14)}
            >
              2 WEEKS
            </ToggleButton>
            <ToggleButton
              type="radio"
              variant="secondary"
              bsPrefix={styles.radioFilterButton}
              checked={filterDaysUntilToday === 30}
              onClick={() => changeFilterMonth()}
            >
              Month
            </ToggleButton>
            <ToggleButton
              type="radio"
              variant="secondary"
              bsPrefix={styles.radioFilterButton}
              checked={filterDaysUntilToday === 365}
              onClick={() => changeFilterYear()}
            >
              Year
            </ToggleButton>
            <ToggleButton
              type="radio"
              variant="secondary"
              // className={styles.radioFilterButton}
              bsPrefix={styles.radioFilterButton}
              checked={filterDaysUntilToday === -1}
              onClick={() => changeFilterDaysUntilNow(-1)}
            >
              All
            </ToggleButton>
          </ButtonGroup>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="chart">
          <Line data={data} options={options} redraw={true} />
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProfitLineChart;
