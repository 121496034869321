import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Button,
  Card,
  Container,
  Col,
  Row,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap";
import { createAccount } from "../../utils/Api";
import useAuth from "../../hooks/useAuth";

import reqMMPamm from "../../model/AccountMoneyManagerPamm";
import { createMoneyManagerPamm } from "../../utils/ApiPamm";
const validateArray = (touched, errors, prop) => {
  let t = false;
  let e = false;
  if (touched.IncentiveAccounts) {
    if (touched.IncentiveAccounts[0][prop]) t = true;
  }
  if (errors.IncentiveAccounts) {
    if (errors.IncentiveAccounts[0][prop]) e = true;
  }
  return t && e;
};
const ConfirmCreateAccount = (props) => (
  <Row className="justify-content-center">
    <Col xs="12" lg="8">
      {props.errors.submit && (
        <Alert className="my-3" variant="danger">
          <div className="alert-message">{props.errors.submit}</div>
        </Alert>
      )}
      <Alert className="my-3" variant="primary">
        <div className="alert-message">
          Do you want to create a Money Manager account?
        </div>
      </Alert>
    </Col>
    <Col xs="12" lg="8">
      <div className="w-100 p-3 text-center">
        <Button className="mx-3" type="submit" disabled={props.isSubmitting}>
          {" "}
          Yes
        </Button>

        <Button
          onClick={() => props.setShowConf(false)}
          disabled={props.isSubmitting}
        >
          No
        </Button>
      </div>
    </Col>
  </Row>
);

const FormikAccountPammCreate = () => {
  const { user } = useAuth();
  const [showConf, setShowConf] = useState(false);
  const [dataSuccess, setDataSuccess] = useState(false);

  const [initUser, setInitUser] = useState(false);

  useEffect(() => {
    if (user) {
      reqMMPamm.Name = user.firstName + " " + user.lastName;
      reqMMPamm.Email = user.email;
      setInitUser(true);
    }
  }, [user]);

  return (
    <>
      {initUser && (
        <Formik
          validationSchema={Yup.object().shape({
            Name: Yup.string().required(),
            Email: Yup.string().required(),
            // Username: Yup.string().required(),
            IncentiveAccounts: Yup.array().of(
              Yup.object().shape({
                IncentiveFee: Yup.string().required(),
                //IncentiveFee: Yup.notOneOf([0]),
              })
            ),
          })}
          initialValues={reqMMPamm}
          //enableReinitialize={true}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (!showConf) {
                setShowConf(true);
                return;
              }
              const account = {
                user: "",
                password: "",
                sid: "1",
                groupName: "",
                leverage: "500",
                currency: "USD",
              };
              setSubmitting(true);

              const moneyManager = await createAccount({
                ...account,
                groupName: "CR-PAMM-MM-USD",
                user: user.id,
                password: user.password,
              });
              const datamm = moneyManager.data;
              const fee = await createAccount({
                ...account,
                groupName: "CR-PAMM-FEE-USD",
                user: user.id,
                password: user.password,
              });
              const datafee = fee.data;

              values.Login = datamm.login;
              values.Username = datamm.login;
              values.IncentiveAccounts[0].Login = datafee.login;
              if (values.IncentiveAccounts[0].PerSelect === "PerLotFee")
                values.IncentiveAccounts[0].PerLotFee =
                  values.IncentiveAccounts[0].PerLotDealFee;
              else
                values.IncentiveAccounts[0].PerDealFee =
                  values.IncentiveAccounts[0].PerLotDealFee;

              const mmPamm = await createMoneyManagerPamm({
                ...values,
              });
              //console.log(mmPamm.data);
              setShowConf(false);
              setDataSuccess(true);
            } catch (error) {
              const message = error.Message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue,
            validateForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              {!dataSuccess && !showConf && (
                <>
                  <Card className="d-none">
                    <Card.Body>
                      <Row className="mb-3 justify-content-center">
                        <h4 className="text-center">Personal Information</h4>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlName"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            readOnly
                            type="text"
                            name="Name"
                            placeholder="Enter your Name"
                            value={values.Name}
                            isInvalid={Boolean(touched.Name && errors.Name)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.Name && (
                            <Form.Control.Feedback type="invalid">
                              {errors.Name}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlEmail"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            readOnly
                            type="email"
                            name="Email"
                            placeholder="Enter your email"
                            value={values.Email}
                            isInvalid={Boolean(touched.Email && errors.Email)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.Email && (
                            <Form.Control.Feedback type="invalid">
                              {errors.Email}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlUserName"
                        >
                          <Form.Label>User Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="Username"
                            placeholder="Enter your new user name"
                            value={values.Username}
                            isInvalid={Boolean(
                              touched.Username && errors.Username
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.Username && (
                            <Form.Control.Feedback type="invalid">
                              {errors.Username}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </Card>

                  <Card>
                    <Card.Body>
                      <Row className="mb-3 justify-content-center">
                        <h4 className="text-center">Settings</h4>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlCurrency"
                        >
                          <Form.Label>Currency</Form.Label>
                          <Form.Select
                            name="Currency"
                            onChange={handleChange}
                            isInvalid={!!errors.Currency}
                            value={values.Currency}
                          >
                            <option value="USD">USD</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.Currency}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlAllocationMode"
                        >
                          <Form.Label>Allocation Mode</Form.Label>
                          <Form.Select
                            name="AllocationMode"
                            onChange={handleChange}
                            isInvalid={!!errors.AllocationMode}
                            value={values.AllocationMode}
                          >
                            <option value="0">By Balance</option>
                            <option value="1">By equity</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.AllocationMode}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlMinimalInvest"
                        >
                          <Form.Label>Minimal Investment</Form.Label>
                          <Form.Control
                            type="text"
                            name="MinInvestment"
                            placeholder="Minimal Investment"
                            value={values.MinInvestment}
                            isInvalid={Boolean(
                              touched.MinInvestment && errors.MinInvestment
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.MinInvestment && (
                            <Form.Control.Feedback type="invalid">
                              {errors.MinInvestment}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Row className="mb-3 justify-content-center">
                        <h4 className="text-center">Preferred Commissions</h4>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlIncentiveFee"
                        >
                          <Form.Label>Incentive Fee</Form.Label>
                          <Form.Control
                            type="text"
                            name="IncentiveAccounts[0].IncentiveFee"
                            isInvalid={validateArray(
                              touched,
                              errors,
                              "IncentiveFee"
                            )}
                            value={values.IncentiveAccounts[0].IncentiveFee}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {validateArray(touched, errors, "IncentiveFee") && (
                            <Form.Control.Feedback type="invalid">
                              {errors.IncentiveAccounts[0].IncentiveFee}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlManagementFee"
                        >
                          <Form.Label>Management Fee</Form.Label>
                          <Form.Control
                            type="text"
                            name="IncentiveAccounts[0].ManagementFee"
                            value={values.IncentiveAccounts[0].ManagementFee}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group className="mb-1" as={Col} xs="6" lg="8">
                          <Form.Label>Annual Management Fee</Form.Label>
                          <Row>
                            <Form.Group className="mb-1" as={Col} xs="6" lg="8">
                              <Form.Select
                                name="IncentiveAccounts[0].Mode"
                                onChange={handleChange}
                                value={values.IncentiveAccounts[0].Mode}
                              >
                                <option value="ByCurrentBalance">
                                  Current balance
                                </option>
                                <option value="ByLastChargeBalance">
                                  Last Charge Balance{" "}
                                </option>
                              </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-1" as={Col} xs="6" lg="4">
                              <Form.Control
                                type="text"
                                name="IncentiveAccounts[0].AnnualManagementFee"
                                value={
                                  values.IncentiveAccounts[0]
                                    .AnnualManagementFee
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Row>
                        </Form.Group>
                        <Form.Group className="mb-1" as={Col} xs="6" lg="8">
                          <Form.Label> Per Lot/Per Deal</Form.Label>
                          <Row>
                            <Form.Group className="mb-1" as={Col} xs="6" lg="8">
                              <Form.Select
                                name="IncentiveAccounts[0].PerSelect"
                                onChange={handleChange}
                                value={values.IncentiveAccounts[0].PerSelect}
                              >
                                <option value="PerLotFee"> Per Lot</option>
                                <option value="PerDealFee">Per Deal</option>
                              </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-1" as={Col} xs="6" lg="4">
                              <Form.Control
                                type="text"
                                name="IncentiveAccounts[0].PerLotDealFee"
                                value={
                                  values.IncentiveAccounts[0].PerLotDealFee
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Row>
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </Card>
                </>
              )}
              {!dataSuccess && !showConf && (
                <div className="w-100 p-3 text-center">
                  <Button className="" type="submit" disabled={isSubmitting}>
                    Create Account
                  </Button>
                </div>
              )}

              {!!dataSuccess && (
                <Alert className="my-3" variant="success">
                  <div className="alert-message">
                    The Money Manager account was created successfully
                  </div>
                </Alert>
              )}
              {showConf && (
                <ConfirmCreateAccount
                  errors={errors}
                  isSubmitting={isSubmitting}
                  setShowConf={setShowConf}
                />
              )}
            </Form>
          )}
        </Formik>
      )}
      {!initUser && (
        <Spinner animation="border" variant="dark" className="me-2" />
      )}
    </>
  );
};

const AccountPammCreatePage = () => {
  return (
    <React.Fragment>
      <Helmet title="Demo" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Create Money Manager</h1>
        <FormikAccountPammCreate />
      </Container>
    </React.Fragment>
  );
};
export default AccountPammCreatePage;
