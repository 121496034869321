import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Tabs, Tab, Col, Container, Row } from "react-bootstrap";
import { getTransactions } from "../../../utils/Api";
import TransactionsHistoryInfo from "./TransactionsHistoryInfo";
import useAuth from "../../../hooks/useAuth";

// prettier-ignore
const _mock_transactions = [
    {
        id: 1,
        managerId: 0,
        createdAt: "2022-03-12T20:05:07.577Z",
        type: "Balance correction",
        fromUserId: 0,
        requestedAmount: 0,
        requestedCurrency: "USD",
        processedAmount: 1004.21,
        processedCurrency: "USD",
        status: "approved",
        psp: "Match Trade",
        pspId: 0,
        pspRuleId: 0,
        fromLoginSid: "2-154439",
        brokerCommission: 0,
        pspCommission: 0,
        processedAt: "2022-03-12T20:05:07.577Z",
        source: 0,
        showToClient: true,
        comment: "string",
        ticket: "Follower",
        platformComment: "string"
    },
    {
        id: 2,
        managerId: 0,
        createdAt: "2022-03-12T20:05:07.577Z",
        type: "transfer out",
        fromUserId: 0,
        requestedAmount: 0,
        requestedCurrency: "USD",
        processedAmount: 1004.21,
        processedCurrency: "USD",
        status: "pending",
        psp: "Match Trade",
        pspId: 0,
        pspRuleId: 0,
        fromLoginSid: "2-154439",
        brokerCommission: 0,
        pspCommission: 0,
        processedAt: "2022-03-12T20:05:07.577Z",
        source: 0,
        showToClient: true,
        comment: "string",
        ticket: "Follower",
        platformComment: "string"
    },
    {
        id: 3,
        managerId: 0,
        createdAt: "2022-03-12T20:05:07.577Z",
        type: "transfer in",
        fromUserId: 0,
        requestedAmount: 0,
        requestedCurrency: "USD",
        processedAmount: 1004.21,
        processedCurrency: "USD",
        status: "approved",
        psp: "Match Trade",
        pspId: 0,
        pspRuleId: 0,
        fromLoginSid: "2-154439",
        brokerCommission: 0,
        pspCommission: 0,
        processedAt: "2022-03-12T20:05:07.577Z",
        source: 0,
        showToClient: true,
        comment: "string",
        ticket: "Follower",
        platformComment: "string"
    },
];

// prettier-ignore
const initialState = {
    pending: [],
    approved: []
};

// prettier-ignore
const TransactionHistory = () => {
    const { user } = useAuth();
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if (!user) return;

        getTransactions(user.id)
            .then(data => {
                // console.log('data', data.data);

                const pending = data.data.filter(item => item.status === 'pending');
                const approved = data.data.filter(item => item.status === 'approved');
                setState({ pending, approved });
                console.log('pending: ', pending)
            })
            .catch(err => console.error('[POST TRANSACTIONS] Error occurred:', err));
    }, [user]);


    return (
        <>
            <Helmet title="Transactions History" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Transactions History</h1>

                <Tabs defaultActiveKey="ptras" id="pending" className="mb-3">
                    <Tab eventKey="ptras" title="Pending Transactions">
                        <TransactionsHistoryInfo data={state.pending} message={"There are no pending transfers"} />
                    </Tab>
                    <Tab eventKey="ptrfs" title="Pending Transfers">
                        <TransactionsHistoryInfo data={[]} message="There are no pending transfers" />
                    </Tab>
                </Tabs>
                <hr />
                <Tabs defaultActiveKey="htras" id="history" className="mb-3">
                    <Tab eventKey="htras" title="Transactions History">
                        <TransactionsHistoryInfo data={state.approved} message="There is no history available" />
                    </Tab>
                    <Tab eventKey="htrfs" title="Transfers History">
                        <TransactionsHistoryInfo data={[]} message="There is no history available" />
                    </Tab>
                </Tabs>
            </Container>
        </>
    );
};

export default TransactionHistory;
