import React from "react";
//import { useTranslation } from "react-i18next";
import { Col, Card, Row, Button, Container } from "react-bootstrap";

import img_mt4_win from "../../assets/img/download/mt4_win.png";
import img_mt5_win from "../../assets/img/download/mt5_win.png";
import img_mt4_ios from "../../assets/img/download/mt4_ios.png";
import img_mt5_ios from "../../assets/img/download/mt5_ios.png";
import img_mt4_android from "../../assets/img/download/mt4_android.png";
import img_mt5_android from "../../assets/img/download/mt5_android.png";

const Download = () => (
  <React.Fragment>
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">Download</h1>
      <Row>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                MT4 Windows
              </Card.Title>
            </Card.Header>
            <Card.Body className="text-center">
              <img
                src={img_mt4_win}
                alt="Stacie Hall"
                className="img-fluid mb-2"
                width="128"
                height="128"
              />
              <Card.Title tag="h5" className="mb-0">
                <span></span>
              </Card.Title>

              <div>
                <a
                  href="https://download.mql5.com/cdn/web/17780/mt4/castlerockfx4setup.exe"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="sm" variant="primary" className="me-1">
                    Download
                  </Button>
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                MT4 IOS
              </Card.Title>
            </Card.Header>
            <Card.Body className="text-center">
              <img
                src={img_mt4_ios}
                alt="Stacie Hall"
                className="img-fluid mb-2"
                width="128"
                height="128"
              />
              <Card.Title tag="h5" className="mb-0">
                <span></span>
              </Card.Title>

              <div>
                <a
                  href="https://apps.apple.com/es/app/metatrader-4/id496212596"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="sm" variant="primary" className="me-1">
                    Download
                  </Button>
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                MT4 Android
              </Card.Title>
            </Card.Header>
            <Card.Body className="text-center">
              <img
                src={img_mt4_android}
                alt="Stacie Hall"
                className="img-fluid mb-2"
                width="128"
                height="128"
              />
              <Card.Title tag="h5" className="mb-0">
                <span></span>
              </Card.Title>

              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&hl=en&referrer=ref_id%3d5188253569587149980%26server%3dCRMarkets-Demo%252cCRMarkets-Live"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="sm" variant="primary" className="me-1">
                    Download
                  </Button>
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                MT5 Windows
              </Card.Title>
            </Card.Header>
            <Card.Body className="text-center">
              <img
                src={img_mt5_win}
                alt="Stacie Hall"
                className="img-fluid mb-2"
                width="128"
                height="128"
              />
              <Card.Title tag="h5" className="mb-0">
                <span></span>
              </Card.Title>

              <div>
                <a
                  href="https://download.mql5.com/cdn/web/prime.intermarket.group/mt5/fxpig5setup.exe"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="sm" variant="primary" className="me-1">
                    Download
                  </Button>
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                MT5 IOS
              </Card.Title>
            </Card.Header>
            <Card.Body className="text-center">
              <img
                src={img_mt5_ios}
                alt="Stacie Hall"
                className="img-fluid mb-2"
                width="128"
                height="128"
              />
              <Card.Title tag="h5" className="mb-0">
                <span></span>
              </Card.Title>

              <div>
                <a
                  href="https://download.mql5.com/cdn/mobile/mt5/ios?server=FXPIG-Server"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="sm" variant="primary" className="me-1">
                    Download
                  </Button>
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4" xl className="d-flex">
          <Card className="flex-fill">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                MT5 Android
              </Card.Title>
            </Card.Header>
            <Card.Body className="text-center">
              <img
                src={img_mt5_android}
                alt="Stacie Hall"
                className="img-fluid mb-2"
                width="128"
                height="128"
              />
              <Card.Title tag="h5" className="mb-0">
                <span></span>
              </Card.Title>

              <div>
                <a
                  href="https://download.mql5.com/cdn/mobile/mt5/android?server=FXPIG-Server"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="sm" variant="primary" className="me-1">
                    Download
                  </Button>
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default Download;
