import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import SignIn from "../../components/auth/SignIn";
import CastleLogo from "../../assets/img/Logo_Castle_Rock.png";
import { Link } from "react-router-dom";

const SignInPage = () => (
  <React.Fragment>
    <Helmet title="Sign In" />
    <div className="text-center mt-4">
      <Image src={CastleLogo} fluid />
    </div>
    <div className="text-center mt-4">
      <h1 className="h1">Sign in to your account</h1>
      <p className="lead"></p>
    </div>

    <Card>
      <Card.Body>
        <div className="m-sm-4">
          <SignIn />
        </div>
        <div className="text-center mt-3">
          Not registered yet? It takes less than a minute <br />
          <Link to="/auth/sign-up">Sign Up</Link>
        </div>
      </Card.Body>
    </Card>
  </React.Fragment>
);

export default SignInPage;
