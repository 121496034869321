import React from "react";

import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";

function SignOut() {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const onClickHandler = (e) => {
    signOut();
    navigate("/auth/sign-in");
  };

  return (
    <div className="text-center mt-3">
      <Button variant="primary" size="lg" onClick={onClickHandler}>
        Sign Out
      </Button>
    </div>
  );
}

export default SignOut;
