import React, { useState, useEffect } from "react";

import { Card, Dropdown, Table, Button } from "react-bootstrap";

import useAuth from "../../../hooks/useAuth";

import { getPamm } from "../../../utils/ApiPamm";
import ConnectPammPage from "./ConnectPamm";

//import { MoreHorizontal } from "react-feather";

const PammAccounts = () => {
  const [cuentas, setCuentas] = useState(null);
  const [mm, setMm] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const getAccountsPamm = async () => {
      const res = await getPamm();
      console.log(res.data);
      setCuentas(res.data.Data);
    };
    if (user) getAccountsPamm();
  }, [user]);

  const handleInvest = (accountmm) => setMm(accountmm);

  return (
    <>
      {!mm && (
        <Card className="flex-fill w-100">
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              PAMM Accounts
            </Card.Title>
          </Card.Header>
          <Table striped className="my-0">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Investors Count</th>
                <th>Min Investment</th>
                <th>Profit</th>
                <th>Total Profit</th>
                <th>Currency</th>
                <th>Registration Date </th>
              </tr>
            </thead>
            <tbody>
              {!!cuentas &&
                cuentas.map((cuenta, i) => (
                  <tr key={i}>
                    <td>
                      <Button
                        onClick={() => {
                          console.log(cuenta);
                          handleInvest(cuenta);
                        }}
                      >
                        Invest
                      </Button>
                    </td>
                    <td>{cuenta.Name}</td>
                    <td>{cuenta.InvestorsCount}</td>
                    <td>{cuenta.MinInvestment}</td>
                    <td>{cuenta.Profit}</td>
                    <td>{cuenta.TotalProfit}</td>
                    <td>{cuenta.Currency}</td>
                    <td>{cuenta.RegistrationDate}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card>
      )}
      {!!mm && <ConnectPammPage mm={mm} setMm={setMm} />}
    </>
  );
};

export default PammAccounts;
