import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Card, Container, Col, Row, Form } from "react-bootstrap";
import { createAccount, AccountType } from "../../utils/Api";
import useAuth from "../../hooks/useAuth";
import ConfirmCreateAccount from "./ConfirmCreateAccount";
import SuccessDataAccount from "./SuccessDataAccount";

// const searchForIdsAccounts = (ids, array) => {
//   const result = [];
//   let el = null;
//   ids.forEach((id) => {
//     el = array.find((element) => element.id === id);
//     if (el) result.push(el);
//   });
//   return result;
// };

const DynamicOptionsSimple = ({ options, prefix }) =>
  options.map((op, i) => (
    <option value={op} key={i}>
      {!!prefix ? prefix + op : op}
    </option>
  ));

const DynamicOptionsAccounts = ({ options }) =>
  options.map((op, i) => {
    let response;
    if (op.optgroup) {
      response = (
        <optgroup label={op.optgroup.label} key={i}>
          {Object.values(op.optgroup.values).map((og, j) => (
            <option value={og.id} key={j}>
              {og.name}
            </option>
          ))}
        </optgroup>
      );
    } else {
      response = (
        <option value={op.id} key={i}>
          {op.name}
        </option>
      );
    }
    return response;
  });

const schema = (category) => {
  let sch = {
    type: Yup.string().required(),
    leverage: Yup.string().required(),
    currency: Yup.string().required(),
  };
  if (category === "demo") {
    sch = { ...sch, initialBalance: Yup.string().required() };
  }
  return Yup.object().shape(sch);
};

const initialValues = (category) => {
  let iv = {
    type: "",
    leverage: "",
    currency: "USD",
  };
  if (category === "demo") {
    iv = { ...iv, initialBalance: "1000" };
  }
  return iv;
};
const groupName = {
  3: "CR-PRO-USD",
  4: "CR-STANDARD-USD",
  6: "CR-RAW-USD",
  10: "demoCastle_usd",
  18: "real\\SZB-U-CRST",
};

const ValidateSelect = ({ type, setFieldValue, account }) => {
  useEffect(() => {
    console.log("entra");

    if (type !== "") {
      setFieldValue("leverage", account.leverages[0], "false");
    }
  }, [type, setFieldValue]);
  return null;
};
const FormikAccountCreate = ({ category, accounts }) => {
  const { user } = useAuth();

  const [showConf, setShowConf] = useState(false);
  const [dataSuccess, setDataSuccess] = useState(null);
  let options = [];
  if (category === "live")
    options = [
      { id: "", name: "Select..." },
      {
        optgroup: {
          label: "MT4",
          values: [accounts[3], accounts[6], accounts[4]],
        },
      },
      {
        optgroup: {
          label: "MT5",
          values: [accounts[18]],
        },
      },
    ];
  else options = [{ id: "", name: "Select..." }, accounts[10]];

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5"> Create Account {category}</Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          validationSchema={schema(category)}
          initialValues={initialValues(category)}
          //enableReinitialize={true}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (!showConf) setShowConf(true);
              else {
                const account = {
                  user: "",
                  password: "",
                  sid: "",
                  groupName: groupName[values.type],
                  leverage: null,
                  initialBalance: null,
                  currency: "",
                };
                setSubmitting(true);
                if (values.type === "10") {
                  account.sid = "3";
                } else {
                  account.sid = "1";
                }
                const response = await createAccount({
                  ...account,
                  ...values,
                  user: user.id,
                  password: user.password,
                });
                setDataSuccess(response.data);
              }
            } catch (error) {
              const message = error.message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <ValidateSelect
                type={values.type}
                setFieldValue={setFieldValue}
                account={accounts[values.type]}
              />

              {!showConf && !dataSuccess && (
                <Row className="mb-3 justify-content-center">
                  <Form.Group
                    className="mb-3"
                    as={Col}
                    xs="12"
                    lg="8"
                    controlId="validationFormik01"
                  >
                    <Form.Label>TYPE</Form.Label>
                    <Form.Select
                      name="type"
                      onChange={handleChange}
                      isInvalid={!!errors.type}
                    >
                      <DynamicOptionsAccounts options={options} />
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.type}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {!!values.type && (
                    <>
                      <Form.Group
                        className="mb-3"
                        as={Col}
                        xs="12"
                        lg="8"
                        controlId="validationFormik02"
                      >
                        <Form.Label>Leverage</Form.Label>
                        <Form.Select
                          name="leverage"
                          onChange={handleChange}
                          isInvalid={!!errors.leverage}
                          value="500"
                        >
                          <DynamicOptionsSimple
                            options={accounts[values.type].leverages}
                            prefix="1:"
                          />
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.leverage}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        as={Col}
                        xs="12"
                        lg="8"
                        controlId="validationFormikUsername"
                      >
                        <Form.Label>Currency</Form.Label>
                        <Form.Select
                          name="currency"
                          onChange={handleChange}
                          isInvalid={!!errors.currency}
                        >
                          <option value="USD">USD</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {errors.currency}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>
                  )}
                  {values.type === "10" && (
                    <Form.Group
                      className="mb-3"
                      as={Col}
                      xs="12"
                      lg="8"
                      controlId="validationFormik03"
                    >
                      <Form.Label>Initial Balance</Form.Label>
                      <Form.Select
                        name="initialBalance"
                        onChange={handleChange}
                        isInvalid={!!errors.initialBalance}
                      >
                        <option value="1000">1000</option>
                        <option value="3000">3000</option>
                        <option value="5000">5000</option>
                        <option value="10000">10000</option>
                        <option value="50000">50000</option>
                        <option value="100000">100000</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.initialBalance}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Row>
              )}
              {showConf && (
                <ConfirmCreateAccount
                  values={values}
                  errors={errors}
                  type={accounts[values.type].name}
                />
              )}
              {!dataSuccess && (
                <div className="w-100 p-3 text-center">
                  <Button className="" type="submit" disabled={isSubmitting}>
                    {showConf ? "Confirm" : "Create Account"}
                  </Button>
                </div>
              )}
              {!!dataSuccess && <SuccessDataAccount values={dataSuccess} />}
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

const AccountCreatePage = ({ category }) => {
  const [accounts, setAccounts] = useState(null);
  useEffect(() => {
    const accountsApi = {};
    console.log("entra padre");
    const consultType = async (category) => {
      const response = await AccountType();
      const typesAccount = response.data;
      typesAccount.forEach((cuenta) => {
        if (cuenta.category === category) accountsApi[cuenta.id] = cuenta;
      });
      setAccounts(accountsApi);
    };
    consultType(category);
  }, []);
  return (
    <React.Fragment>
      <Helmet title="Demo" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Account {category}</h1>
        {!!accounts && (
          <FormikAccountCreate category={category} accounts={accounts} />
        )}
      </Container>
    </React.Fragment>
  );
};
export default AccountCreatePage;
