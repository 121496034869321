import moment from "moment";
import React from "react";
import { Card, Table } from "react-bootstrap";

export default function ClosedOrdersTable({ ClosedOrders }) {
  return (
    <Table striped className="my-0">
      <thead>
        <tr>
          <th style={{ width: "15%" }}>Order</th>
          <th className="">Symbol</th>
          <th className="">Type</th>
          <th>Volume</th>
          <th className="">Open time</th>
          <th className="">Open price</th>
          <th className="">Close time</th>
          <th className="">Close price</th>
        </tr>
      </thead>
      <tbody>
        {ClosedOrders &&
          ClosedOrders.map((order) => (
            <tr key={order.Id}>
              <td>{order.MtOrderId}</td>
              <td>{order.Symbol}</td>
              <td></td>
              <td>{order.Volume}</td>
              <td>{moment(order.OpenTime).format("LL")}</td>
              <td>{order.OpenPrice}</td>
              <td>{moment(order.CloseTime).format("LL")}</td>
              <td>{order.ClosePrice}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}
