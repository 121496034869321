import React, { useEffect, useState } from "react";

import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const TimedOut = () => {
  const timeout = 1000 * 60 * 5;
  const [remaining, setRemaining] = useState(timeout);
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const SignOut = () => {
    signOut();
    navigate("/auth/sign-in");
  };

  const handleOnIdle = () => SignOut();

  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    setRemaining(getRemainingTime());

    setInterval(() => {
      setRemaining(getRemainingTime());
    }, 1000);
  }, []);

  return <span />;
};

export default TimedOut;
