import React, { useState, useEffect } from "react";
//import { useTranslation } from "react-i18next";
import { Badge, Col, Card, Row } from "react-bootstrap";

import { DollarSign, ShoppingBag } from "react-feather";
import { getAccounts, getTradesOpen } from "../../../utils/Api";

import useAuth from "../../../hooks/useAuth";

//import illustration from "../../../assets/img/illustrations/customer-support.png";

const Statistics = () => {
  const { user } = useAuth();
  // console.log(user);

  const [balance, setBalance] = useState(null);
  const [ordenes, setOrdenes] = useState(null);

  useEffect(() => {
    const balance = {
      Cuentas: 0,
      Wallet: 0,
    };
    const ConsultarCuentas = async () => {
      const res = await getAccounts(user.id);
      const cts = res.data;
      cts.forEach((cuenta) => {
        if (cuenta.accountTypeId === 1) balance.Wallet = cuenta.balance;
        if (cuenta.accountTypeId !== 10)
          balance.Cuentas += parseFloat(cuenta.balance);
      });
      setBalance(balance);
    };
    const ConsultarOrdenes = async () => {
      const res = await getTradesOpen(user.id);
      const ordenes = res.data;
      setOrdenes(ordenes.length);
    };
    if (user) {
      ConsultarCuentas();
      ConsultarOrdenes();
    }
  }, []);
  //const { t } = useTranslation();

  return (
    <Row>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">$ {balance?.Wallet}</h3>
                <p className="mb-2">Balance Wallet</p>
                <div className="mb-0">
                  <Badge bg="" className="badge-soft-success me-2">
                    +5.35%
                  </Badge>
                  <span className="text-muted">Since last week</span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <DollarSign className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">{ordenes}</h3>
                <p className="mb-2">Pending Orders</p>
                <div className="mb-0">
                  <Badge bg="" className="badge-soft-danger me-2">
                    -4.25%
                  </Badge>
                  <span className="text-muted">Since last week</span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <ShoppingBag className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl className="d-flex">
        <Card className="flex-fill">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">$ {balance?.Cuentas}</h3>
                <p className="mb-2">Balance Cuentas</p>
                <div className="mb-0">
                  <Badge bg="" className="badge-soft-success me-2">
                    +8.65%
                  </Badge>
                  <span className="text-muted">Since last week</span>
                </div>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <DollarSign className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Statistics;
