import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import { getIDUser, getUserData, createNewUser } from "../utils/Api";
import { authPamm } from "../utils/ApiPamm";
//import { setSession } from "../utils/jwt";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessUser = JSON.parse(
          window.localStorage.getItem("accessUser")
        );
        console.log(accessUser);

        if (accessUser) {
          await authPamm("API", "CRFX2022!");
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: accessUser,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password) => {
    let response = await getIDUser(email, password);
    const idUser = response.data.id;
    if (idUser === "0") throw Error("el usuario o contraseña son incorrectos");
    response = await getUserData(idUser);
    const userRta = response.data;
    userRta.password = password;
    window.localStorage.setItem("accessUser", JSON.stringify(userRta));
    await authPamm("API", "CRFX2022!");
    dispatch({
      type: SIGN_IN,
      payload: {
        user: userRta,
      },
    });
  };

  const signOut = async () => {
    window.localStorage.removeItem("accessUser");
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (data) => {
    await createNewUser(data);
    dispatch({ type: SIGN_UP });
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
