import React from "react";

import { Card, Col, Row } from "react-bootstrap";
import imgUnderConst from "../../assets/img/underConstruction.png";

const UnderConstruction = (props) => (
  <Row className="justify-content-center">
    <Col xs="12" lg="8">
      <Card>
        <Card.Body className="text-center">
          <img
            src={imgUnderConst}
            alt="Stacie Hall"
            className="img-responsive mt-2"
            width="428"
            height="428"
          />
          <Card.Title tag="h5" className="mb-0">
            Option under construction.
          </Card.Title>
        </Card.Body>
      </Card>
    </Col>
  </Row>
);

export default UnderConstruction;
