/*
Funciones utiles reusables en distintos componentes
*/
import React from "react";

/*Recibe un arreglo de datos y genera las <option></option> para cargar en un Input Select
Parametros:
- options: arreglo de objetos a iterar
- clave: dato que se guardara en el campo "value" del option
- value: dato que se mostrara en el combo del select
*/
export const DynamicOptionsSelect = ({ options, clave, value }) =>
  options.map((op, i) => {
    let response = (
      <option value={op[clave]} key={i}>
        {op[value]}
      </option>
    );
    return response;
  });

/*
  Recibe un String con formato yyyy-mm-dd y lo retorna en formato dd/mm/yyyyy
  */
export const formatStrDateYYYYMMDDToDDMMYYYY = (dateStr, separator) => {
  const charSeparator =
    separator === undefined || separator === "" ? "-" : separator;
  const [year, month, day] = dateStr.split("-");
  let newStrDate = `${day}${charSeparator}${month}${charSeparator}${year}`;
  return newStrDate;
};

/*
  Recibe un String con formato dd/mm/yyyyy y lo retorna en formato yyyy-mm-dd
  */
export const formatStrDateDDMMYYYYToYYYYMMDD = (dateStr, separator) => {
  const charSeparator =
    separator === undefined || separator === "" ? "-" : separator;

  const [day, month, year] = dateStr.split(charSeparator);
  let newStrDate = `${year}${charSeparator}${month}${charSeparator}${day}`;
  return newStrDate;
};
export const queryParams = (obj) => {
  let str = "";
  let value = "";
  for (var key in obj) {
    if (str !== "") {
      str += "&";
    }
    if (Array.isArray(obj[key])) value = obj[key].join();
    else value = obj[key];

    str += key + "=" + encodeURIComponent(value);
  }
  return str;
};
