import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
//import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import Image from "react-bootstrap/Image";

import CastleLogo from "../../assets/img/Logo_Castle_Rock_Blanco.png";

const Sidebar = ({ items, showFooter = "false" }) => {
  const { isOpen } = useSidebar();
  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <Image src={CastleLogo} fluid />
          </a>

          <SidebarNav items={items} />
          {/* {!!showFooter && <SidebarFooter />} */}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
