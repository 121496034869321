import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import useAuth from "../../../hooks/useAuth";
// prettier-ignore
import { Button, Card, Col, Container, Row, Form, OverlayTrigger, Table, Alert, Spinner } from "react-bootstrap";
import { getSystemAccounts, newTransfer } from "../../../utils/Api";
import { getTooltip } from "./Tooltips";

// prettier-ignore
const stepState = [
    'INITIAL INFORMATION','TRANSFER DETAILS','CONFIRMATION', 'STATUS'
];

// prettier-ignore
const initialState = {
    page: 1,
    loading: true,
    info: null,
    balance: null,
    wallets: [],
    fromSid: null,
    fromLogin: null,
    toSid: null,
    toLogin: null,
    status: null,
    amount: 0,
    currency: null,
    comment: null,
    type: "Transfer Type",
    show: false,
};

// prettier-ignore
const TransferFunds = () => {

    const { user } = useAuth();
    const [state, setState] = useState(initialState);
    
    useEffect(() => {
        if (user && user.id) {
            (async () => {
                const response = await getSystemAccounts(user.id);
                const wallets = [];
                response.data.forEach(w => wallets.push({
                    id: w.groupName,
                    sid: w.serverId,
                    login: w.login,
                    balance: w.balance,
                    name: `${w.groupName} ${w.login} (${w.balance} ${w.currency})`,
                }));
                setState({ 
                    ...state, 
                    show: true,
                    loading: false,
                    status: response.data[0].tradingStatus,
                    currency: response.data[0].currency,
                    wallets 
                });
            })();
        }
    }, [user]);

    const onContinueClick = () => {
        if (state.page === 1) {
            //
        }
        setState({...state, page: state.page + 1});
        if (state.page === 3) {
            const obj = {
                fromSid: state.fromSid,
                fromLogin: state.fromLogin,
                toSid: state.toSid,
                toLogin: state.toLogin,
                amount: state.amount,
                comment: state.comment,
                // status: "approved",          // not required field
                // type: "Internal Transfer",    // not required field
            };
            console.log('will be sent: ', obj);
            newTransfer(obj)
            .then(data => console.log('API response: ', data))
            .catch(err => console.error('error happend on New Transfer: ', err))
        }
    }

    const onBackClick = () => {
        setState({...state, page: state.page - 1});
    }

    const onChangeNumber = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setState({...state, amount: +e.target.value})
        }
    }

    const onChooseWalletFrom = (e) => {
        const idx = state.wallets.findIndex(w => w.id == e.target.value);
        if (idx > -1) {
            setState({
                ...state,
                fromSid: state.wallets[idx].sid,
                fromLogin: state.wallets[idx].login,
                balance: 1 //state.wallets[idx].balance,
            });
        }
    }

    const onChooseWalletTo = (e) => {
        const idx = state.wallets.findIndex(w => w.id == e.target.value);
        if (idx > -1) {
            setState({
                ...state,
                toSid: state.wallets[idx].sid,
                toLogin: state.wallets[idx].login,
            });
        }
    }

    const spinner = state.loading ? <Spinner style={{position:'absolute',left:250, top:10}} animation="border" variant="primary" /> : null;

    const page1 = (
        <>
            <Row>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Transfer From</Form.Label>
                        <Form.Select onChange={onChooseWalletFrom}>
                            <option disabled selected>Select</option>
                            {state.wallets.map(row => (
                                <option key={row.id} value={row.id}>{row.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Transfer To</Form.Label>
                        <Form.Select onChange={onChooseWalletTo}>
                            <option disabled selected>Select</option>
                            {state.wallets.map(row => (
                                <option key={row.id} value={row.id}>{row.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Row>
            <Row>
                <OverlayTrigger overlay={getTooltip(+state.balance === 0 ? 'Your Wallet is Empty!' : '')} placement="top">
                    <Col md="auto">
                        <Button type="submit" disabled={+state.balance === 0 || !state.toSid || !state.fromSid} variant="primary" size="lg" onClick={onContinueClick}>Continue</Button>
                        {spinner}
                    </Col>
                </OverlayTrigger>
            </Row>
        </>
    );

    const page2 = (
        <>
            <Row>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Transfer Amount (USD)</Form.Label>
                        <Form.Control type='number' value={state.amount} onChange={onChangeNumber} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control as="textarea" value={state.comment} rows={3} onChange={(e) => setState({...state, comment: e.target.value})} />
                    </Form.Group>
                </Form>
            </Row>
            <Row>
                <Col md="auto">
                    <Button type="submit" variant="light" size="lg" onClick={onBackClick} style={{marginRight:10}}>Back</Button>
                    <OverlayTrigger overlay={getTooltip(!state.amount || +state.amount === 0 ? 'Amount must be a number!' : '')} placement="top">
                        <span>
                        <Button type="submit" disabled={+state.amount === 0} variant="primary" size="lg" onClick={onContinueClick}>Continue</Button>
                        </span>
                    </OverlayTrigger>
                </Col>
            </Row>
        </>
    );

    const page3 = (
        <>
            <Row>
                <Table className="mb-3">
                    <tbody>
                    <tr>
                        <td>Transfer from</td>
                        <td className="text-end text-success">{state.fromLogin}</td>
                    </tr>
                    <tr>
                        <td>Transfer To</td>
                        <td className="text-end text-success">{state.toLogin}</td>
                    </tr>
                    <tr>
                        <td>Will be deducted from {state.fromLogin}</td>
                        <td className="text-end text-success">{state.amount} {state.currency}</td>
                    </tr>
                    <tr>
                        <td>Will be credited to {state.toLogin}</td>
                        <td className="text-end text-success">{state.amount} {state.currency}</td>
                    </tr>
                    </tbody>
                </Table>
            </Row>
            <Row>
              <Col md="auto">
                    <Button type="submit" variant="light" size="lg" onClick={onBackClick} style={{marginRight:10}}>Back</Button>
                    <Button type="submit" variant="primary" size="lg" onClick={onContinueClick}>Continue</Button>
                </Col>
            </Row>
        </>
    );

    const page4 = (
        <>
            <Row>
                <Col>
                    <Alert variant="primary">
                        <div className="alert-message">
                            <h4 className="alert-heading">Well done!</h4>
                            <p>
                                Aww yeah, you successfully read this important alert message. This
                                example text is going to run a bit longer so that you can see how
                                spacing within an alert works with this kind of content.
                            </p>
                            <hr />
                            <p className="mb-0">
                                Whenever you need to, be sure to use margin utilities to keep things
                                nice and tidy.
                            </p>
                        </div>
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col md="auto">
                    <Button type="submit" variant="light" size="lg" onClick={() => setState({...state, page:1})} style={{marginRight:10}}>One more transfer</Button>
                </Col>
            </Row>
        </>
    );

    return (
        <>
            <Helmet title="Transfer Funds" />
            <Container fluid className="p-0">
                <h1 className="h3 mb-3">Transfer Funds</h1>
                <Card>
                    <Card.Header>
                        <Card.Title tag="h5" className="mb-0">Step {state.page}. {stepState[state.page-1]}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row className="justify-content-md-center">
                            <Col xs md="12" lg="4">
                                {state.page === 1 && page1}
                                {state.page === 2 && page2}
                                {state.page === 3 && page3}
                                {state.page === 4 && page4}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
};

export default TransferFunds;
