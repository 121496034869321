import React from "react";

import { Card, Col, Row, Table, Alert } from "react-bootstrap";

const SuccessDataAccount = (props) => (
  <Row className="justify-content-center">
    <Col xs="12" lg="8">
      <Alert className="my-3" variant="success">
        <div className="alert-message">
          The account was created successfully
        </div>
      </Alert>

      <Card className="flex-fill w-100">
        <Card.Header>
          <Card.Title tag="h5" className="mb-0">
            Account information
          </Card.Title>
        </Card.Header>
        <Table striped className="my-0">
          <tbody>
            <tr>
              <th scope="row">Account Number</th>
              <td>{props.values.login}</td>
            </tr>
            <tr>
              <th scope="row">Server</th>
              <td>{props.values.serverId}</td>
            </tr>
            <tr>
              <th scope="row">Type</th>
              <td>{props.values.groupName}</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </Col>
  </Row>
);

export default SuccessDataAccount;
