import React from "react";
//import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import {
  Badge,
  Button,
  Card /*, Col, Container, Row */,
} from "react-bootstrap";

import { Briefcase, Home, MapPin /*, MessageSquare */ } from "react-feather";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import avatar_M from "../../../assets/img/avatars/avatar_M.png";
import avatar_F from "../../../assets/img/avatars/avatar_F.png";

import useAuth from "../../../hooks/useAuth";

const ProfileDetails = () => {
  const { user } = useAuth();
  const imgProfile =
    user && (user.title == null || user.title === "Mr") ? avatar_M : avatar_F;

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          Profile Details
        </Card.Title>
      </Card.Header>
      <Card.Body className="text-center">
        <img
          src={imgProfile}
          alt="Stacie Hall"
          className="img-fluid rounded-circle mb-2"
          width="128"
          height="128"
        />
        <Card.Title tag="h5" className="mb-0">
          {user && <span>{user.firstName + " " + user.lastName}</span>}
        </Card.Title>
        <div className="text-muted mb-2">
          {user && <span>{user.email}</span>}
        </div>

        <div>
          <Link to="account-create-live">
            <Button size="sm" variant="primary" className="me-1">
              Create account
            </Button>
          </Link>
          <Link to="../pages/under-construction">
            <Button size="sm" variant="primary">
              Deposit
            </Button>
          </Link>
        </div>
      </Card.Body>

      <hr className="my-0" />

      <Card.Body>
        <Card.Title tag="h5">Shortcuts</Card.Title>

        <Badge bg="primary" className="me-2 my-1">
          <Button size="sm" variant="primary" className="me-2">
            Cuentas
          </Button>
        </Badge>
        <Badge bg="primary" className="me-2 my-1">
          <Button size="sm" variant="primary" className="me-2">
            Fondos
          </Button>
        </Badge>
        <Badge bg="primary" className="me-2 my-1">
          <Button size="sm" variant="primary" className="me-2">
            IB
          </Button>
        </Badge>
        <Badge bg="primary" className="me-2 my-1">
          <Button size="sm" variant="primary" className="me-2">
            Cr-copy
          </Button>
        </Badge>
        <Badge bg="primary" className="me-2 my-1">
          <Button size="sm" variant="primary" className="me-2">
            CRPAMM
          </Button>
        </Badge>
      </Card.Body>

      <hr className="my-0" />
      <Card.Body>
        <Card.Title tag="h5">About</Card.Title>
        <ul className="list-unstyled mb-0">
          <li className="mb-1">
            <Home width={14} height={14} className="me-1" /> Lives in{" "}
            <Link to="/dashboard/default">San Francisco, SA</Link>
          </li>

          <li className="mb-1">
            <Briefcase width={14} height={14} className="me-1" /> Works at{" "}
            <Link to="/dashboard/default">GitHub</Link>
          </li>
          <li className="mb-1">
            <MapPin width={14} height={14} className="me-1" /> From{" "}
            <Link to="/dashboard/default">Boston</Link>
          </li>
        </ul>
      </Card.Body>
      <hr className="my-0" />
      <Card.Body>
        <Card.Title tag="h5">Elsewhere</Card.Title>

        <ul className="list-unstyled mb-0">
          <li className="mb-1">
            <FontAwesomeIcon icon={faGlobe} fixedWidth className="me-1" />
            <Link to="/dashboard/default">staciehall.co</Link>
          </li>
          <li className="mb-1">
            <FontAwesomeIcon icon={faTwitter} fixedWidth className="me-1" />
            <Link to="/dashboard/default">Twitter</Link>
          </li>
          <li className="mb-1">
            <FontAwesomeIcon icon={faFacebook} fixedWidth className="me-1" />
            <Link to="/dashboard/default">Facebook</Link>
          </li>
          <li className="mb-1">
            <FontAwesomeIcon icon={faInstagram} fixedWidth className="me-1" />
            <Link to="/dashboard/default">Instagram</Link>
          </li>
          <li className="mb-1">
            <FontAwesomeIcon icon={faLinkedin} fixedWidth className="me-1" />
            <Link to="/dashboard/default">LinkedIn</Link>
          </li>
        </ul>
      </Card.Body>
    </Card>
  );
};

const Profile = () => (
  <>
    <ProfileDetails />
  </>
);

export default Profile;
