import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";

import SignOut from "../../components/auth/SignOut";
import avatar_M from "../../assets/img/avatars/avatar_M.png";
import avatar_F from "../../assets/img/avatars/avatar_F.png";
import useAuth from "../../hooks/useAuth";

const SignOutPage = () => {
  const { user } = useAuth();

  return (
    <React.Fragment>
      <Helmet title="Sign Out" />
      <div className="text-center mt-4">
        <h2>
          Good bye {user && <span>{user.firstName + " " + user.lastName}</span>}
        </h2>
        <p className="lead">You are about to close your session</p>
      </div>

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                src={
                  user && (user.title == "Ms" || user.title === "Mrs")
                    ? avatar_F
                    : avatar_M
                }
                alt="Chris Wood"
                className="img-fluid rounded-circle"
                width="132"
                height="132"
              />
            </div>
            <SignOut />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignOutPage;
