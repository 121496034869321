import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faUpload } from "@fortawesome/free-solid-svg-icons";

import ID_guidelines from "../../../assets/img/uploadDocs/ID_Guidelines.png";
import useAuth from "../../../hooks/useAuth";
import { countriesList, uploadDocument } from "../../../utils/Api";
import UploadedDocuments from "./UploadedDocuments";
import { DynamicOptionsSelect } from "../../../utils/Utils";

import * as Yup from "yup";
import { Formik } from "formik";
import AlertCustom from "../../../utils/AlertCustom";
import InputFileBase64 from "../../../utils/InputFileBase64";

//const FILE_SIZE = 160 * 1024;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

function GetTypeDocumentComponent(props) {
  const documentType = props.state.documentType;
  switch (documentType) {
    case "4":
      return (
        <DocumentIDPassport
          state={props.state}
          setStatePadre={props.setState}
          user={props.user}
        />
      );
    case "5":
      return (
        <DocumentProofAddress
          state={props.state}
          setStatePadre={props.setState}
          user={props.user}
        />
      );
    default:
      return "";
  }
}

const schemaIDPassport = () => {
  let sch = {
    expiresAt: Yup.string().required("Expiration Date is required"),
    idNumber: Yup.number().required("Document ID is required"),
    typeDoc: Yup.string().required("Document type is required"),
    country: Yup.string().required("Country is required"),
    // fileFront: Yup.mixed().notnu.required("Front side file is required"),
    // fileBack: Yup.mixed().required("Back side file is required"),
  };
  return Yup.object().shape(sch);
};

const initialValuesIDPassport = () => {
  let iv = {
    expiresAt: "",
    idNumber: "",
    typeDoc: "",
    country: "",
    fileFront: null,
    fileBack: null,
  };
  return iv;
};

const DocumentIDPassport = ({ state, setStatePadre, user }) => {
  const [stateFileFront, setStateFileFront] = useState(null);
  const [stateFileBack, setStateFileBack] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  return (
    <Card>
      <Card.Body>
        <Formik
          validationSchema={schemaIDPassport()}
          initialValues={initialValuesIDPassport()}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const documentData = {
                config: "4",
                user: user.id,
                status: "pending",

                //Armar la estructura del JSon para enviar el file
                // fileFront: values.fileFront,
                // fileBack: values.fileBack,

                data: {
                  date_of_expiry: values.expiresAt,
                  document_number: values.idNumber,
                  type: values.typeDoc,
                  country_of_issue: values.country,
                  front_side: [
                    {
                      file: stateFileFront.base64.substring(
                        stateFileFront.base64.indexOf(",") + 1
                      ), //base64
                      name: stateFileFront.name,
                    },
                  ],
                  back_side: [
                    {
                      file: stateFileBack.base64.substring(
                        stateFileBack.base64.indexOf(",") + 1
                      ), //base64
                      name: stateFileBack.name,
                    },
                  ],
                },
              };
              setSubmitting(true);
              const resp = await uploadDocument(documentData);
              setUploadSuccess(true);
              setStatePadre({
                ...state,
                docmuentUploadedID: resp.data.id,
              });
            } catch (error) {
              const message = error.message || "Something went wrong";
              console.log(error);
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
              setUploadSuccess(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            isInvalid,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="expiresAt">Expiration date</Form.Label>
                <Form.Control
                  type="date"
                  name="expiresAt"
                  placeholder=""
                  isInvalid={Boolean(touched.expiresAt && errors.expiresAt)}
                  onChange={handleChange}
                />
                {!!touched.expiresAt && (
                  <Form.Control.Feedback type="invalid">
                    {errors.expiresAt}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="idNumber">Document ID</Form.Label>
                <Form.Control
                  type="text"
                  name="idNumber"
                  placeholder=""
                  //value={values.idNumber}
                  isInvalid={Boolean(touched.idNumber && errors.idNumber)}
                  onChange={handleChange}
                />
                {!!touched.idNumber && (
                  <Form.Control.Feedback type="invalid">
                    {errors.idNumber}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="typeDoc">Type</Form.Label>
                <Form.Select
                  name="typeDoc"
                  id="typeDoc"
                  onChange={handleChange}
                  isInvalid={Boolean(touched.typeDoc && errors.typeDoc)}
                  value={values.typeDoc}
                >
                  <option value="">Select type</option>
                  <option value="Passport">Passport</option>
                  <option value="National ID">National ID</option>
                </Form.Select>
              </Form.Group>
              {state.countries && (
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="country">Country</Form.Label>
                  <Form.Select
                    name="country"
                    id="country"
                    onChange={handleChange}
                    isInvalid={Boolean(touched.country && errors.country)}
                    value={values.country}
                  >
                    <option value="">Select country</option>
                    <DynamicOptionsSelect
                      options={state.countries}
                      clave="code"
                      value="name"
                    />
                  </Form.Select>
                </Form.Group>
              )}
              <InputFileBase64
                name="fileFront"
                description="Front side file"
                returnFileState={setStateFileFront}
                isInvalid={Boolean(touched.fileFront && errors.fileFront)}
                handleChange={handleChange}
              />
              <InputFileBase64
                name="fileBack"
                description="Back side file"
                returnFileState={setStateFileBack}
                isInvalid={Boolean(touched.fileBack && errors.fileBack)}
                handleChange={handleChange}
              />
              <div>
                <img
                  src={ID_guidelines}
                  alt="Stacie Hall"
                  className="img-fluid"
                />
              </div>
              {uploadSuccess && (
                <div>
                  <AlertCustom
                    variant="success"
                    message="The document was successfully uploaded."
                  />
                </div>
              )}
              {errors.submit && (
                <Alert className="my-3" variant="warning">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              <div className="text-center mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  name="btnSubmit"
                  disabled={isSubmitting}
                >
                  Upload
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

const schemaProofAddress = () => {
  let sch = {
    country: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),
    address: Yup.string().required("Address is required"),
    zipCode: Yup.string().required("Zip Code is required"),
    file: Yup.mixed().required("File is required"),
    // .test(
    //   "fileSize",
    //   "File too large",
    //   (value) => value && value.size <= FILE_SIZE
    // )
    // .test(
    //   "fileFormat",
    //   "Unsupported Format",
    //   (value) => value && SUPPORTED_FORMATS.includes(value.type)
    // ),
  };
  return Yup.object().shape(sch);
};

const initialValuesProofAddress = () => {
  let iv = {
    country: "",
    city: "",
    address: "",
    zipCode: "",
    file: null,
  };
  return iv;
};

const DocumentProofAddress = ({ state, setStatePadre, user }) => {
  const [stateFile, setStateFile] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  return (
    <Card>
      <Card.Body>
        <Formik
          validationSchema={schemaProofAddress()}
          initialValues={initialValuesProofAddress()}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              //Armar la estructura del JSon para enviar el file
              const documentData = {
                user: user.id,
                status: "pending",
                config: "5",
                data: {
                  country: values.country,
                  city: values.city, //values.city === "" ? user.city : values.city,
                  address: values.address,
                  postal_code: values.zipCode,
                  file: [
                    {
                      file: stateFile.base64.substring(
                        stateFile.base64.indexOf(",") + 1
                      ), //base64
                      name: stateFile.name,
                    },
                  ],
                },
              };
              setSubmitting(true);
              const resp = await uploadDocument(documentData);
              setUploadSuccess(true);
              setStatePadre({
                ...state,
                docmuentUploadedID: resp.data.id,
              });
            } catch (error) {
              const message = error.message || "Something went wrong";
              console.log(error);
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
              setUploadSuccess(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            isInvalid,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              {state.countries && (
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="country">Country</Form.Label>
                  <Form.Select
                    name="country"
                    id="country"
                    onChange={handleChange}
                    isInvalid={Boolean(touched.country && errors.country)}
                    value={values.country}
                  >
                    <option value="">Select country</option>
                    <DynamicOptionsSelect
                      options={state.countries}
                      clave="code"
                      value="name"
                    />
                  </Form.Select>
                </Form.Group>
              )}
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  placeholder=""
                  value={values.city}
                  isInvalid={Boolean(touched.city && errors.city)}
                  onChange={handleChange}
                />
                {!!touched.city && (
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  placeholder=""
                  value={values.address}
                  isInvalid={Boolean(touched.address && errors.address)}
                  onChange={handleChange}
                />
                {!!touched.address && (
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  name="zipCode"
                  placeholder=""
                  value={values.zipCode}
                  isInvalid={Boolean(touched.zipCode && errors.zipCode)}
                  onChange={handleChange}
                />
                {!!touched.zipCode && (
                  <Form.Control.Feedback type="invalid">
                    {errors.zipCode}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <InputFileBase64
                name="file"
                description="File"
                returnFileState={setStateFile}
                isInvalid={Boolean(touched.file && errors.file)}
                handleChange={handleChange}
              />
              {!!touched.file && (
                <Form.Control.Feedback type="invalid">
                  {errors.file}
                </Form.Control.Feedback>
              )}
              {uploadSuccess && (
                <div>
                  <AlertCustom
                    variant="success"
                    message="The document was successfully uploaded."
                  />
                </div>
              )}
              {errors.submit && (
                <Alert className="my-3" variant="warning">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              <div className="text-center mt-3">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  name="btnSubmit"
                  disabled={isSubmitting}
                >
                  Upload
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

//Estado inicial de las variables que se usan en el componente
const initialState = {
  documentType: "",
  countries: [],
  docmuentUploadedID: "", //Cuando cambie este valor deberia renderizarse el componente UploadedDocuments
};

const UploadDocuments = () => {
  const { user } = useAuth();
  const [state, setState] = useState(initialState);

  //useEffect para controlar la carga de los paises
  useEffect(() => {
    const getCountries = async () => {
      const response = await countriesList();
      setState({
        ...state,
        countries: response.data,
      });
    };
    getCountries();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Settings" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Upload Documents</h1>
        <Col md="9" xl="10">
          {/* Fila con los documentos subidos por el usuario */}
          <Row>
            <UploadedDocuments docmuentUploadedID={state.docmuentUploadedID} />
          </Row>

          {/* Fila con los formularios para subir un nuevo documento */}
          <Row>
            <Card>
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  Upload New Document
                </Card.Title>
              </Card.Header>
              <Card.Body className="text-left">
                <div>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="country">I want to upload</Form.Label>
                    <Form.Select
                      name="docType"
                      id="docType"
                      onChange={(e) => {
                        setState({
                          ...state,
                          documentType: e.target.value,
                        });
                      }}
                    >
                      <option value="">Select...</option>
                      <option value="4">ID/Passport</option>
                      <option value="5">Proof of Address</option>
                    </Form.Select>
                  </Form.Group>
                  {state.documentType && (
                    <GetTypeDocumentComponent
                      state={state}
                      setState={setState}
                      user={user}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Row>
        </Col>
      </Container>
    </React.Fragment>
  );
};
export default UploadDocuments;
