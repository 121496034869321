import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Button,
  Card,
  Container,
  Col,
  Row,
  Form,
  Alert,
  Spinner,
  Modal,
} from "react-bootstrap";

import useAuth from "../../../hooks/useAuth";
import { getAccounts } from "../../../utils/Api";
import {
  getInvestors,
  createRequestAtachInvestor,
} from "../../../utils/ApiPamm";
const queryParams = (obj) => {
  let str = "";
  let value = "";
  for (var key in obj) {
    if (str !== "") {
      str += "&";
    }
    if (Array.isArray(obj[key])) value = obj[key].join();
    else value = obj[key];

    str += key + "=" + encodeURIComponent(value);
  }
  return str;
};

const ConfirmConnectPamm = (props) => (
  <Row className="justify-content-center">
    <Col xs="12" lg="8">
      {props.errors.submit && (
        <>
          <Alert className="my-3" variant="danger">
            <div className="alert-message">{props.errors.submit}</div>
          </Alert>
          <div className="w-100 p-3 text-center">
            <Button onClick={() => props.setShowConf(false)}>Close </Button>
          </div>
        </>
      )}
    </Col>
    {!props.errors.submit && (
      <Col xs="12" lg="8">
        <Alert className="my-3" variant="primary">
          <div className="alert-message">Do you want to connect?</div>
        </Alert>
        <div className="w-100 p-3 text-center">
          <Button className="mx-3" type="submit" disabled={props.isSubmitting}>
            {" "}
            Yes
          </Button>
          <Button
            onClick={() => props.setShowConf(false)}
            disabled={props.isSubmitting}
          >
            No
          </Button>
        </div>
      </Col>
    )}
  </Row>
);

const ConnectPamm = ({ mm, setMm }) => {
  const { user } = useAuth();
  const [showConf, setShowConf] = useState(false);
  const [dataSuccess, setDataSuccess] = useState(false);
  const [investors, setInvestors] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const AccountsConsultInvestors = async (id) => {
      const pammInv = [];
      let investors = {};

      const response = await getAccounts(id);
      const cuentas = response.data;
      console.log(cuentas);
      cuentas.forEach((cuenta) => {
        if (cuenta.groupName === "CR-PAMM-INV-USD") pammInv.push(cuenta.login);
      });
      //MasterId
      if (pammInv.length) {
        const resInv = await getInvestors(queryParams({ logins: pammInv }));
        resInv.data.Data.forEach((inv) => {
          if (inv.MasterId > 0) investors[inv.Id] = inv;
          //investors[inv.Id] = inv;
        });
        // console.log(investors);
      }

      setInvestors(investors);
    };
    if (user) {
      AccountsConsultInvestors(user.id);
    }
  }, [user]);

  const handleClose = () => setShowModal(false);

  return (
    <>
      {!!investors && Object.keys(investors).length > 0 && (
        <Formik
          validationSchema={Yup.object().shape({
            AccountId: Yup.string().required(),
          })}
          initialValues={{
            AccountId: "",
            MmId: mm.Id,
          }}
          //enableReinitialize={true}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (investors[values.AccountId].Balance === 0) {
                setShowModal(true);
                return;
              }
              if (!showConf) {
                setShowConf(true);
                return;
              }
              console.log(values);
              await createRequestAtachInvestor(values);
              setShowConf(false);
              setDataSuccess(true);
            } catch (error) {
              const message = error.Message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue,
            validateForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              {/* {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )} */}
              {!dataSuccess && !showConf && (
                <>
                  <Card>
                    <Card.Body>
                      <Row className="mb-3 justify-content-center">
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlFrom"
                        >
                          <Form.Label>From</Form.Label>
                          <Form.Select
                            name="AccountId"
                            onChange={handleChange}
                            value={values.AccountId}
                            isInvalid={!!errors.AccountId}
                          >
                            <option value="">to select...</option>
                            {Object.values(investors).map((op, i) => (
                              <option value={op.Id} key={i}>
                                {op.Username +
                                  " (Investor Id " +
                                  op.Id +
                                  ") -  ( $ " +
                                  op.Balance +
                                  " )"}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.AccountId}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlTo"
                        >
                          <Form.Label>To</Form.Label>
                          <Form.Select
                            name="MmId"
                            onChange={handleChange}
                            readonly
                          >
                            <option value={mm.Id}>
                              {mm.Name + " (" + mm.Username + ")"}
                            </option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.MmId}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </Card>
                </>
              )}
              {!dataSuccess && !showConf && (
                <div className="w-100 p-3 text-center">
                  <Button
                    className="mx-3"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Connect
                  </Button>
                  <Button onClick={() => setMm(null)}>Back </Button>
                </div>
              )}

              {!!dataSuccess && (
                <>
                  <Alert className="my-3" variant="success">
                    <div className="alert-message">Connected successfully</div>
                  </Alert>
                  <div className="w-100 p-3 text-center">
                    <Button onClick={() => setMm(null)}>Close </Button>
                  </div>
                </>
              )}
              {showConf && (
                <>
                  <ConfirmConnectPamm
                    errors={errors}
                    isSubmitting={isSubmitting}
                    setShowConf={setShowConf}
                  />
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
      {!investors && (
        <Spinner animation="border" variant="dark" className="me-2" />
      )}
      {!!investors && Object.keys(investors).length === 0 && (
        <>
          <Alert className="my-3" variant="warning">
            <div className="alert-message">
              Does not have an investor account to connect
            </div>
          </Alert>
          <div className="w-100 p-3 text-center">
            <Button onClick={() => setMm(null)}>Back </Button>
          </div>
        </>
      )}
      {showModal && (
        <Modal show={showModal} onHide={handleClose} centered>
          <Modal.Header closeButton>Important</Modal.Header>
          <Modal.Body className="text-center m-3">
            <Alert className="my-3" variant="warning">
              <div className="alert-message">Deposit fund in pamm inv</div>
            </Alert>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleClose()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const ConnectPammPage = ({ mm, setMm }) => {
  console.log(mm);
  return (
    <React.Fragment>
      <Helmet title="Invest" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Connect Money Manager</h1>
        <ConnectPamm mm={mm} setMm={setMm} />
      </Container>
    </React.Fragment>
  );
};
export default ConnectPammPage;
