import React, { Fragment } from "react";

import { Alert } from "react-bootstrap";

/*Componente para Alerts customizado.
Parametros:
- variant: indica el estilo del Alert (danger, success, confirm, etc)
- message: string con el mensaje que se mostrara dentro del Alert
*/
const AlertCustom = (props) => {
  return (
    <Fragment>
      {props && (
        <Alert className="my-3" variant={props.variant}>
          <div className="alert-message">{props.message}</div>
        </Alert>
      )}
    </Fragment>
  );
};

export default AlertCustom;
