import React, { useState, useEffect } from "react";
import { getInvestors } from "../../utils/ApiPamm";
import { queryParams } from "../../utils/Utils";
import {
  Button,
  Card,
  Container,
  Modal,
  Table,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { getAccountsForCategory } from "../../utils/Api";

const AccountDetail = ({ account, showModal, handleClose }) => {
  //Cargamos el modal con los datos de la cuenta recibida
  return (
    <React.Fragment>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>Account Detail</Modal.Header>
        <Modal.Body className="text-center m-3">
          <Card>
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                Account {account.login}
                <hr />
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Title tag="h5" className="mb-0"></Card.Title>
              <div className="text-muted mb-2">
                <Table striped className="my-0">
                  {account && (
                    <tbody>
                      <tr>
                        <td>Server ID</td>
                        <td>{account.serverId}</td>
                      </tr>
                      <tr>
                        <td>Created At</td>
                        <td>{account.createdAt}</td>
                      </tr>
                      <tr>
                        <td>Group Name</td>
                        <td>{account.groupName}</td>
                      </tr>
                      <tr>
                        <td>Currency</td>
                        <td>{account.currency}</td>
                      </tr>
                      <tr>
                        <td>Leverage</td>
                        <td>{account.leverage}</td>
                      </tr>
                      <tr>
                        <td>Balance</td>
                        <td>{account.balance + " " + account.currency}</td>
                      </tr>
                      <tr>
                        <td>Equity</td>
                        <td>{account.equity + " " + account.currency}</td>
                      </tr>
                      <tr>
                        <td>Margin</td>
                        <td>{account.margin + " " + account.currency}</td>
                      </tr>
                      <tr>
                        <td>Floating P/L</td>
                        <td>{account.pnl + " " + account.currency}</td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const Projects = () => {
  const [cuentas, setCuentas] = useState(null);
  const [account, setAccount] = useState(null); //Si se necesita consultar algo de la cuenta via API, se debe agregar un useEffect
  const [showModal, setShowModal] = useState(false);

  const handleSetAccount = (account) => {
    setAccount(account);
    if (account) setShowModal(true);
  };

  const loader = (
    <div className="mx-auto">
      <Spinner animation="border" variant="primary" />
    </div>
  );

  const handleClose = () => setShowModal(false);
  const { user } = useAuth();

  useEffect(() => {
    const ConsultarCuentas = async (id) => {
      const response = await getAccountsForCategory(id);

      if (response.live) {
        response.live.forEach(async (item) => {
          item.pnl = 0;

          if (item.groupName === "CR-PAMM-INV-USD") {
            const res = await getInvestors(queryParams({ logins: item.login }));
            const resInv = res.data;

            if (resInv && resInv.Data.length > 0) {
              item.balance = resInv.Data[0].Balance;
              item.equity = resInv.Data[0].Equity;
              item.margin = resInv.Data[0].Margin;
              item.pnl = resInv.Data[0].Profit;
            }
          }
        });
      }
      // console.log("storing: ", response);
      setTimeout(() => setCuentas(response), 500);
    };

    if (user) ConsultarCuentas(user.id); // id=1055 - Simon
  }, [user]);

  let qlist = (
    <tr>
      <td colSpan={10} align="center">
        {loader}
      </td>
    </tr>
  );

  if (cuentas && cuentas.live) {
    qlist = cuentas.live.map((cuenta, i) => (
      <tr key={i}>
        <td>{cuenta.groupName}</td>
        <td>{cuenta.login}</td>
        <td>1:{cuenta.leverage}</td>
        <td>{cuenta.currency}</td>
        <td>{cuenta.balance}</td>
        <td>{cuenta.credit}</td>
        <td>{cuenta.equity}</td>
        <td>{cuenta.margin}</td>
        <td>{cuenta.pnl}</td>
        <td>
          <Button variant="primary" onClick={() => handleSetAccount(cuenta)}>
            View
          </Button>
        </td>
      </tr>
    ));
  }

  const wallet =
    cuentas && cuentas.wallet ? (
      <tr>
        <td>{cuentas.wallet[0].groupName}</td>
        <td>{cuentas.wallet[0].login}</td>
        <td>{cuentas.wallet[0].currency}</td>
        <td>{cuentas.wallet[0].balance}</td>
      </tr>
    ) : (
      <tr>
        <td colSpan={4} align="center">
          {loader}
        </td>
      </tr>
    );

  let brok = null;

  if (cuentas) {
    if (cuentas.brokeree_social_platform) {
      brok = cuentas.brokeree_social_platform.map((cuenta, i) => (
        <tr key={i}>
          <td>{cuenta.groupName}</td>
          <td>{cuenta.login}</td>
          <td>1:{cuenta.leverage}</td>
          <td>{cuenta.currency}</td>
          <td>{cuenta.balance}</td>
          <td>{cuenta.credit}</td>
          <td>{cuenta.equity}</td>
          <td>{cuenta.margin}</td>
        </tr>
      ));
    } else {
      brok = (
        <tr>
          <td colSpan={8} align="center">
            Empty
          </td>
        </tr>
      );
    }
  } else {
    brok = (
      <tr>
        <td colSpan={8} align="center">
          {loader}
        </td>
      </tr>
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <Card className="flex-fill w-100">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                Wallet Account
              </Card.Title>
            </Card.Header>
            <Table striped className="my-0">
              <thead>
                <tr>
                  <th>Type</th>
                  <th className="">Wallet Number</th>
                  <th>Currency</th>
                  <th className="">Balance</th>
                </tr>
              </thead>
              <tbody>{wallet}</tbody>
            </Table>
          </Card>
          <Card className="flex-fill w-100">
            <Card.Header>
              <Card.Title tag="h5" className="mb-0">
                Live Accounts
              </Card.Title>
            </Card.Header>
            <Table striped className="my-0">
              <thead>
                <tr>
                  <th>Type</th>
                  <th className="">Trading Platform Login </th>
                  <th className="">Leverage</th>
                  <th>Currency</th>
                  <th className="">Balance</th>
                  <th className="">Credit</th>
                  <th className="">Equity</th>
                  <th className="">Margin</th>
                  <th className="">Floating P/L</th>
                  <th className=""></th>
                </tr>
              </thead>
              <tbody>{qlist}</tbody>
            </Table>
            {account && (
              <AccountDetail
                account={account}
                showModal={showModal}
                handleClose={handleClose}
              />
            )}
          </Card>
          <Card className="flex-fill w-100">
            <Card.Header>
              <Card.Title tag="h7" className="mb-0">
                Copy Trader Accounts
              </Card.Title>
            </Card.Header>
            <Table striped className="my-0">
              <thead>
                <tr>
                  <th>Type</th>
                  <th className="">Trading Platform Login </th>
                  <th className="">Leverage</th>
                  <th>Currency</th>
                  <th className="">Balance</th>
                  <th className="">Credit</th>
                  <th className="">Equity</th>
                  <th className="">Margin</th>
                </tr>
              </thead>
              <tbody>{brok}</tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Projects;
