import React, { useState, Fragment } from "react";
import { Form } from "react-bootstrap";
/*Componente para poner un input file con transformacion a base 64.
Parametros props:
- name: nombre del input file
- description: texto a mostrar en el identificador del input
- returnFileState: funcion que permite actualizar un "useState" en el padre para enviarle los datos del state local (con formato {file: "valor", base64URL: "valor"}).

*/

//Estado inicial de las variables que se usan en el componente
// const initialState = {
//   file: null,
//   base64URL: "",
// };

const InputFileBase64 = (props) => {
  // const [state, setState] = useState(initialState);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let base64 = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        base64 = reader.result;
        resolve(base64);
      };
    });
  };

  const handleFileInputChange = (e) => {
    let file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        console.log("File is", file);
        // setState({
        //   base64URL: result,
        //   file,
        // });

        //retornamos el state local al padre
        props.returnFileState && props.returnFileState(file);
      })
      .catch((err) => {
        console.log(err);
      });

    // setState({
    //   file: e.target.files[0],
    // });
  };

  return (
    <Fragment>
      <Form.Group className="mb-3">
        <Form.Label>
          {props.description ? props.description : "File"}
        </Form.Label>
        <Form.Control
          type="file"
          name={props.name ? props.name : "file"}
          placeholder=""
          onChange={(e) => {
            handleFileInputChange(e);
            props.handleChange && props.handleChange(e); //por si tiene Formik
          }}
          //onChange={handleFileInputChange}
        />
      </Form.Group>
    </Fragment>
  );
};

export default InputFileBase64;
