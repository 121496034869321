import axios from "axios";
import moment from "moment";

let inst = null;
export const getInstace = () => {
  if (inst === null) {
    inst = axios.create({
      baseURL: "https://pamm.castlerockfx.com:8081",
    });
    inst.interceptors.response.use(
      (response) => response,
      (error) =>
        Promise.reject(
          (error.response && error.response.data) || "Something went wrong"
        )
    );
  }
  return inst;
};

//Recupera el ID del usuario (proceso de login)
export const authPamm = async (username, password) => {
  const instance = getInstace();
  const user = {
    Username: username,
    Password: password,
  };
  const auth = await instance.post("/api/pamm/auth/login", user);
  setToken(auth.data);
};
export function setToken(token) {
  getInstace().defaults.headers.common["Authorization"] =
    token.TokenType + " " + token.Token;
}

//Recupera los datos del usuario (perfil)
export function getPamm() {
  const instance = getInstace();
  return instance.get("/api/pamm/masters");
}

export const getPammMasters = (params = "") => {
  const instance = getInstace();
  return instance.get(`/api/pamm/masters${params}`);
};

export const getPammMasterProfit = (id = "0") => {
  const instance = getInstace();
  const we2 = moment(new Date()).format("YYYY-MM-DD");
  const ws2 = moment(new Date()).add(-15, "d").format("YYYY-MM-DD");
  return instance.get(
    `/api/pamm/summary/profit/${id}/?period=Day&from=${ws2}&to=${we2}`
  );
};

export function createMoneyManagerPamm(data) {
  const instance = getInstace();
  return instance.post("/api/pamm/requests/create/createMm", data);
}
export function createRequestInvestor(data) {
  const instance = getInstace();
  return instance.post("/api/pamm/requests/create/createInvestor", data);
}

export function executeRequestInvestor(data) {
  // console.log(data);
  const instance = getInstace();
  return instance.post("/api/pamm/requests/execute/createInvestor", data);
}
export function getInvestors(queryParams) {
  // console.log(queryParams);
  const instance = getInstace();
  return instance.get("/api/pamm/investors?" + queryParams);
}

export function getInvestor(investorId) {
  const instance = getInstace();
  return instance.get("/api/pamm/investors/" + investorId);
}

export function getOrdersCloses(investorId, filters = "") {
  // console.log(data);
  const instance = getInstace();
  return instance.get(
    "/api/pamm/investors/orders/closed/" + investorId + "?" + filters
  );
}
export function getOperationsBalances(investorId, filters = "") {
  // console.log(data);
  const instance = getInstace();
  return instance.get(
    "/api/pamm/investors/balance/operations/" + investorId + "?" + filters
  );
}
export function createRequestAtachInvestor(data) {
  console.log(data);
  const instance = getInstace();
  return instance.post("/api/pamm/requests/create/attachInvestor", data);
}
