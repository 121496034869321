import React, { useState, useEffect } from "react";

import { Badge, Card, Spinner, Table } from "react-bootstrap";

import useAuth from "../../../hooks/useAuth";
import AlertCustom from "../../../utils/AlertCustom";
import { getUploadedDocuments } from "../../../utils/Api";

//Usado para poder mostar el boton con el estado de los documentos en la tabla
const stateDocuments = {
  approved: "success",
  declined: "danger",
  pending: "primary",
};

//Estado inicial de las varianles que se usan en el componente
const initialState = {
  loading: true,
  documents: null,
};

const UploadedDocuments = (docmuentUploadedID) => {
  const { user } = useAuth();
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const ConsultarDocumentos = async (id) => {
      const response = await getUploadedDocuments(id);
      setState({
        ...state,
        documents: response,
        loading: false,
      });
    };

    if (user) ConsultarDocumentos(user.id);
  }, [docmuentUploadedID]);

  const spinner = state.loading ? (
    <>
      <div className="text-center">
        <Spinner animation="border" variant="primary" />
      </div>
    </>
  ) : null;

  return (
    <>
      <Card className="flex-fill w-100">
        <Card.Header>
          <Card.Title tag="h5" className="mb-0">
            Uploaded Documents
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Table striped className="my-0">
            <thead>
              <tr>
                <th>Type</th>
                <th>Due Date</th>
                <th>State</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {!!state.documents &&
                state.documents.length > 0 &&
                state.documents.map((document, i) => (
                  <tr key={i}>
                    <td>{document.type}</td>
                    <td>{document.expiresAt}</td>
                    <td>
                      <Badge
                        key={i}
                        bg={stateDocuments[document.status]}
                        className="me-1 mb-1"
                      >
                        {document.status}
                      </Badge>
                    </td>
                    <td>
                      {document.declineReason ? document.declineReason : ""}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {!!state.documents && state.documents.length === 0 && (
            <AlertCustom
              variant="warning"
              message="No documents have been uploaded"
            />
          )}
          {spinner}
        </Card.Body>
      </Card>
    </>
  );
};

export default UploadedDocuments;
