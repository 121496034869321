import React from "react";
import { Line } from "react-chartjs-2";
import { getPammMasterProfit } from "../../../../utils/ApiPamm";
import usePalette from "../../../../hooks/usePalette";

const PammDashboardChart = (props) => {
  const palette = usePalette();
  const initial_data = {
    labels: ["fds", "fsd", "fsd"],
    datasets: [
      {
        fill: true,
        backgroundColor: palette["primary-light"],
        borderColor: palette.primary,
        data: [1, 3, 5, 2, 32, -12, 32, 2],
      },
    ],
  };

  const [state, setState] = React.useState(initial_data);

  const loadGraph = async (id) => {
    const rt = await getPammMasterProfit(id);
    let _vals = [];

    if (rt && rt.data) {
      rt.data.forEach((rate) => _vals.push(rate.Value));
    }
    if (_vals.length > 0) {
      setState({
        labels: [..._vals],
        datasets: [
          {
            fill: true,
            backgroundColor: palette["primary-light"],
            borderColor: palette.primary,
            data: [..._vals],
          },
        ],
      });
    }
  };

  React.useEffect(() => {
    props && props.data && loadGraph(props.data);
  }, [props.data]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          display: false,
          gridLines: {
            color: "rgba(0,0,0,0.05)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          display: false,
          //   borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff",
            zeroLineColor: palette.primary,
          },
        },
      ],
    },
  };

  return <Line data={state} options={options} />;
};

export default PammDashboardChart;
