import axios from "axios";

let inst = null;
let inst2 = null;

const getInstanceClientCastlerock = () => {
  if (inst === null) {
    inst = axios.create({
      baseURL: "https://client.castlerockfx.com",
      headers: {
        Authorization:
          //        "Bearer MWI4YTI1ZTEyNzNhMjlhNmNiOTUxNjdmNGRhMDhiZjI3M2IyMWQ3NzgzNjMzZDgyZDFmOTJkOWQyMWQ0Yzc0YQ",   arieeel
          // "Bearer MmM5YmQzODc5ODM0Y2MxOGRjMGVkZGEzMDg4NDU0NzMzYzE4MjYwMTA0ODhiMmFiNTg5ZmI1M2ZlMDVhYTNhOA", //test
          "Bearer NmU0NDEzOWRhNmYzZjg4ZWMwMmE3NWYwNTdmODVmZjhiMzY5ZjM0YzY1MmUwMTE1NjliMzllOWU2NmJlYTg2ZA",
      },
    });
    inst.interceptors.response.use(
      (response) => response,
      (error) =>
        Promise.reject(
          (error.response && error.response.data) || "Something went wrong"
        )
    );
  }
  return inst;
};

const getInstance = () => {
  if (inst2 === null) {
    inst2 = axios.create({ baseURL: "https://castlerockfx.mx/api" });
    inst2.interceptors.response.use(
      (response) => response,
      (error) =>
        Promise.reject(
          (error.response && error.response.data) || "Something went wrong"
        )
    );
  }
  return inst2;
};

//Recupera el ID del usuario (proceso de login)
export function getIDUser(email, password) {
  const instance = getInstanceClientCastlerock();
  const user = {
    email: email,
    password: password,
  };
  return instance.post("/rest/user/check_credentials?version=1.0.0", user);
}

//Recupera los datos del usuario (perfil)
export function getUserData(idUser) {
  const instance = getInstanceClientCastlerock();
  return instance.get("/rest/users/" + idUser + "?version=1.0.0");
}

//Permite actualizar los datos del usuario logueado
// {
//   "user": "1372",
//   ...
// }
export function updateUserData(data) {
  const instance = getInstanceClientCastlerock();
  return instance.post("/rest/users/update?version=1.0.0", data);
}

//Permite modificar el password del usuario
// {
//   "user": "1",
//   "password": "",
//   "notifyEnabled": false
// }
export function updateUserPassword(data) {
  const instance = getInstanceClientCastlerock();
  return instance.post("/rest/user/set_password?version=1.0.0", data);
}

//Permite crear un nuevo usuario en el sistema.
export function createNewUser(data) {
  console.log(data);
  const instance = getInstanceClientCastlerock();
  const response = instance.post("/rest/users/new?version=1.0.0", data);
  return response;
}

//Recupera el listado de cuentas del usuario idUser
export function getAccounts(idUser) {
  const instance = getInstanceClientCastlerock();
  return instance.post("/rest/accounts?version=1.0.0", {
    userId: idUser,
  });
}

export const getAccountsForCategory = async (idUser) => {
  const accounts = {};
  const instance = getInstanceClientCastlerock();
  const atfc = await AccountTypeObject();
  const res = await instance.post("/rest/accounts?version=1.0.0", {
    userId: idUser,
  });
  console.log(res.data);
  res.data.forEach((cuenta) => {
    if (cuenta.accountTypeId > 0) {
      let category = atfc[cuenta.accountTypeId].category;
      if (accounts[category]) {
        accounts[category].push(cuenta);
      } else {
        accounts[category] = [cuenta];
      }
    }
  });
  return accounts;
};

export function getTradesOpen(idUser) {
  const instance = getInstanceClientCastlerock();
  return instance.post("/rest/trades?version=1.0.0", {
    userId: idUser,
    closeDate: {
      begin: "1970-01-01 00:00:00",
      end: "1970-01-01 00:00:00",
    },
  });
}

export function createAccount(data) {
  //data.user = 1;
  console.log(data);
  const instance = getInstanceClientCastlerock();
  return instance.post("/rest/accounts/new?version=1.0.0", data);
}

export function AccountType() {
  const instance = getInstanceClientCastlerock();
  return instance.get("rest/dict/account-type?version=1.0.0");
}

//Retorna la lista de paises con el codigo de pais
export const countriesList = async () => {
  const instance = getInstanceClientCastlerock();
  return await instance.get("/rest/dict/countries?version=1.0.0");
};

//Retorna la lista de ciudades del codigo de pais recibido
export const citiesList = async (codCountry) => {
  const instance = getInstanceClientCastlerock();
  const res = await instance.post("/rest/dict/cities?version=1.0.0", {
    country: codCountry,
  });
  return res.data;
};

//Retorna los documentos que tiene subidos el id de usuario recibido como parametro.
export const getUploadedDocuments = async (idUser) => {
  const instance = getInstanceClientCastlerock();
  const res = await instance.post("/rest/documents?version=1.0.0", {
    userIds: [idUser],
  });
  return res.data;
};

//Permite registrar un nuevo documento asociado al usuario logueado.
export const uploadDocument = async (data) => {
  const instance = getInstanceClientCastlerock();
  const res = await instance.post("/rest/documents/new?version=1.0.0", data);
  return res.data;
};

export const AccountTypeObject = async () => {
  const object = {};
  const instance = getInstanceClientCastlerock();
  const res = await instance.get("rest/dict/account-type?version=1.0.0");
  res.data.forEach((cuenta) => {
    object[cuenta.id] = cuenta;
  });
  return object;
};

export async function getTransactions(data) {
  const instance = getInstance();
  return await instance.post(
    "/transactions",
    new URLSearchParams({
      fromUserId: data,
    })
  );
}

export const getSystemAccounts = async (idUser) => {
  const instance = getInstance();
  return await instance.post(
    "/accounts",
    new URLSearchParams({
      userId: idUser,
    })
  );
};

export const newTransfer = async (transfer) => {
  const instance = getInstance();
  return await instance.post(
    "/transfer/new",
    new URLSearchParams({
      ...transfer,
    })
  );
};
