import React from "react";

import { Dropdown } from "react-bootstrap";

import { LogIn, LogOut, PieChart, Settings, User } from "react-feather";

import avatar_M from "../../assets/img/avatars/avatar_M.png";
import avatar_F from "../../assets/img/avatars/avatar_F.png";
import useAuth from "../../hooks/useAuth";

const getMenuUser = () => (
  <Dropdown.Menu drop="end">
    <Dropdown.Item href="/pages/settings">
      <User size={18} className="align-middle me-2" />
      Profile
    </Dropdown.Item>
    {/*<Dropdown.Item>
      <PieChart size={18} className="align-middle me-2" />
      Analytics
    </Dropdown.Item>
    <Dropdown.Divider />
    <Dropdown.Item href="/pages/settings">
      <Settings size={18} className="align-middle me-2" />
      Settings
    </Dropdown.Item> */}
    <Dropdown.Item href="/auth/sign-out">
      <LogOut size={18} className="align-middle me-2" />
      Sign out
    </Dropdown.Item>
  </Dropdown.Menu>
);

const getMenuNoUser = () => (
  <Dropdown.Menu drop="end">
    <Dropdown.Item href="/auth/sign-in">
      <LogIn size={18} className="align-middle me-2" />
      Sign in
    </Dropdown.Item>
  </Dropdown.Menu>
);

const NavbarUser = () => {
  const { user } = useAuth();
  const imgProfile =
    user && (user.title === "" || user.title == null || user.title === "Mr")
      ? avatar_M
      : avatar_F;
  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <img
            src={imgProfile}
            className="avatar img-fluid rounded-circle me-1"
            alt="Chris Wood"
          />
          <span className="text-dark">
            {user && <span>{user.firstName + " " + user.lastName}</span>}
          </span>
        </Dropdown.Toggle>
      </span>
      {user ? getMenuUser() : getMenuNoUser()}
    </Dropdown>
  );
};

export default NavbarUser;
