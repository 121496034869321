import { Table, Badge } from "react-bootstrap";
import "./TransactionsHistoryInfo.css";

// prettier-ignore
const TransactionsHistoryInfo = (props) => {

    if (!props.data || props.data.length === 0) {
        return <div className="callout">{props.message}</div>;
    }

    return (
        <Table striped className="my-0">
            <thead>
                <tr>
                    <th>ID</th>
                    <th className="text-end">Type</th>
                    <th className="d-none d-xl-table-cell text-end">Status</th>
                    <th className="d-none d-xl-table-cell text-end">Payment System</th>
                    <th className="d-none d-xl-table-cell text-end">Account</th>
                    <th className="d-none d-xl-table-cell text-end">Date</th>
                    <th className="d-none d-xl-table-cell text-end">Amount</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map(row => (
                    <tr key={row.id}>
                        <td>{row.id}</td>
                        <td className="text-end">{row.type}</td>
                        <td className="d-none d-xl-table-cell text-end">{row.status === 'approved' ? <Badge bg="success">approved</Badge> : (row.status === 'pending' ? <Badge bg="info">pending</Badge> : row.status)}</td>
                        <td className="d-none d-xl-table-cell text-end">{row.psp}</td>
                        <td className="d-none d-xl-table-cell text-end">{row.ticket}</td>
                        <td className="d-none d-xl-table-cell text-end">{row.processedAt}</td>
                        <td className="d-none d-xl-table-cell text-end">{row.processedAmount ? ((+row.processedAmount).toFixed(2) + ' ' + row.processedCurrency ) : ''}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

export default TransactionsHistoryInfo;
