const AccountInvestorPamm = {
  Name: "",
  Username: "",
  Country: "",
  Phone: "",
  Email: "",
  LinkToken: "",
  Currency: "USD",
  Investments: 0,
  Comment: "",
};
export default AccountInvestorPamm;
