import React, { useState } from "react";

import { Card, Table, Button } from "react-bootstrap";
// import OrdersCloses from "./OrdersCloses";
// import OperationsBalance from "./OperationsBalance";

const InvestorInfo = ({ investor }) => {
  // const [showOrders, setShowOrders] = useState(false);
  // const handleShowOrders = () => setShowOrders(!showOrders);

  return (
    <>
      <Card className="flex-fill w-100">
        <Card.Body>
          <div className="w-100 p-1">
            <h4>
              Money Manager:{" "}
              {" " + investor.MmName + " (" + investor.MasterId + ")"}{" "}
            </h4>
          </div>
          <Table striped responsive>
            <thead>
              <tr>
                {/* <th style={{ width: "8%" }}></th> */}
                <th>Name</th>
                <th>Currency</th>
                <th>Equity</th>
                <th>Balance</th>
                <th>Investments</th>
                <th>TotalProfit </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {/* <td>
                  <Button onClick={handleShowOrders}>Detail</Button>
                </td> */}
                <td>{investor.Name}</td>
                <td>{investor.Currency}</td>
                <td>{investor.Equity}</td>
                <td>{investor.Balance}</td>
                <td>{investor.Investments}</td>
                <td>{investor.TotalProfit}</td>
              </tr>
            </tbody>
          </Table>
          {/* {showOrders && (
            <>
              <OrdersCloses investor={investor} />
              <OperationsBalance investor={investor} />
            </>
          )} */}
        </Card.Body>
      </Card>
    </>
  );
};

export default InvestorInfo;
