import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Button,
  Card,
  Container,
  Col,
  Row,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap";

import useAuth from "../../hooks/useAuth";

import reqInvestorPamm from "../../model/AccountInvestorPamm";
import { createRequestInvestor } from "../../utils/ApiPamm";

const ConfirmCreateAccount = (props) => (
  <Row className="justify-content-center">
    <Col xs="12" lg="8">
      {props.errors.submit && (
        <Alert className="my-3" variant="danger">
          <div className="alert-message">{props.errors.submit}</div>
        </Alert>
      )}
      <Alert className="my-3" variant="primary">
        <div className="alert-message">
          You wish to create an Investor account which will allow you to access
          the PAMM and Money Manager accounts of the Castlerock FX system?
        </div>
      </Alert>
    </Col>
    <Col xs="12" lg="8">
      <div className="w-100 p-3 text-center">
        <Button className="mx-3" type="submit" disabled={props.isSubmitting}>
          {" "}
          Yes
        </Button>

        <Button
          onClick={() => props.setShowConf(false)}
          disabled={props.isSubmitting}
        >
          No
        </Button>
      </div>
    </Col>
  </Row>
);

const FormikAccountPammCreate = () => {
  const { user } = useAuth();
  const [initUser, setInitUser] = useState(false);

  useEffect(() => {
    if (user) {
      reqInvestorPamm.Name = user.firstName + " " + user.lastName;
      reqInvestorPamm.Email = user.email;
      reqInvestorPamm.Country = user.country;
      reqInvestorPamm.Phone = user.phone;
      setInitUser(true);
    }
  }, [user]);

  const [showConf, setShowConf] = useState(false);
  const [dataSuccess, setDataSuccess] = useState(false);

  return (
    <>
      {initUser && (
        <Formik
          validationSchema={Yup.object().shape({
            Name: Yup.string().required(),
            Email: Yup.string().required(),
            // Username: Yup.string().required(),
            Phone: Yup.string().required(),
            Country: Yup.string().required(),
          })}
          initialValues={reqInvestorPamm}
          //enableReinitialize={true}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            values = {
              ...values,
              Username: user.firstName + " " + user.lastName,
            };
            console.log({ values });
            try {
              if (!showConf) {
                // console.log("user: ", user);
                setShowConf(true);

                return;
              }
              setSubmitting(true);
              const createInv = await createRequestInvestor({
                ...values,
              });
              setShowConf(false);
              setDataSuccess(true);
            } catch (error) {
              const message = error.Message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              {!dataSuccess && !showConf && (
                <>
                  <Card>
                    <Card.Body>
                      <Row className="mb-3 justify-content-center">
                        <h4 className="text-center">Information</h4>
                        {/* <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlUserName"
                        >
                          <Form.Label>User Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="Username"
                            placeholder="Enter your new user name"
                            value={values.Username}
                            isInvalid={Boolean(
                              touched.Username && errors.Username
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.Username && (
                            <Form.Control.Feedback type="invalid">
                              {errors.Username}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group> */}
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlCurrency"
                        >
                          <Form.Label>Currency</Form.Label>
                          <Form.Select
                            name="Currency"
                            onChange={handleChange}
                            isInvalid={!!errors.Currency}
                            value={values.Currency}
                          >
                            <option value="USD">USD</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.Currency}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlInvestments"
                        >
                          <Form.Label>Amount to invest</Form.Label>
                          <Form.Control
                            type="text"
                            name="Investments"
                            value={values.Investments}
                            isInvalid={Boolean(
                              touched.Investments && errors.Investments
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.Investments && (
                            <Form.Control.Feedback type="invalid">
                              {errors.Investments}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlInvestments"
                        >
                          <Form.Label>Comment</Form.Label>
                          <Form.Control
                            type="text"
                            name="Comment"
                            value={values.Comment}
                            isInvalid={Boolean(
                              touched.Comment && errors.Comment
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.Comment && (
                            <Form.Control.Feedback type="invalid">
                              {errors.Comment}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card className="d-none">
                    <Card.Body>
                      <Row className="mb-3 justify-content-center">
                        <h4 className="text-center">Personal Information</h4>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlName"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            readOnly
                            type="text"
                            name="Name"
                            placeholder="Enter your Name"
                            value={values.Name}
                            isInvalid={Boolean(touched.Name && errors.Name)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.Name && (
                            <Form.Control.Feedback type="invalid">
                              {errors.Name}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlEmail"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            readOnly
                            type="email"
                            name="Email"
                            placeholder="Enter your email"
                            value={values.Email}
                            isInvalid={Boolean(touched.Email && errors.Email)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.Email && (
                            <Form.Control.Feedback type="invalid">
                              {errors.Email}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlPhone"
                        >
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                            readOnly
                            type="text"
                            name="Phone"
                            placeholder="Enter your Phone"
                            value={values.Phone}
                            isInvalid={Boolean(touched.Phone && errors.Phone)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.Phone && (
                            <Form.Control.Feedback type="invalid">
                              {errors.Phone}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-1"
                          as={Col}
                          xs="12"
                          lg="8"
                          controlId="controlCountry"
                        >
                          <Form.Label>Country</Form.Label>
                          <Form.Control
                            readOnly
                            type="text"
                            name="Country"
                            placeholder="Enter your Country"
                            value={values.Country}
                            isInvalid={Boolean(
                              touched.Country && errors.Country
                            )}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          {!!touched.Country && (
                            <Form.Control.Feedback type="invalid">
                              {errors.Country}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </Row>
                    </Card.Body>
                  </Card>
                </>
              )}
              {!dataSuccess && !showConf && (
                <div className="w-100 p-3 text-center">
                  <Button className="" type="submit" disabled={isSubmitting}>
                    Create Account
                  </Button>
                </div>
              )}

              {!!dataSuccess && (
                <Alert className="my-3" variant="success">
                  <div className="alert-message">
                    The Investor account was created successfully
                  </div>
                </Alert>
              )}
              {showConf && (
                <ConfirmCreateAccount
                  errors={errors}
                  isSubmitting={isSubmitting}
                  setShowConf={setShowConf}
                />
              )}
            </Form>
          )}
        </Formik>
      )}
      {!initUser && (
        <Spinner animation="border" variant="dark" className="me-2" />
      )}
    </>
  );
};

const AccountInvestorCreatePage = () => {
  return (
    <React.Fragment>
      <Helmet title="Demo" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Create Investor</h1>
        <FormikAccountPammCreate />
      </Container>
    </React.Fragment>
  );
};
export default AccountInvestorCreatePage;
