import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Nav, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import AlertCustom from "../../utils/AlertCustom";
import { citiesList, countriesList } from "../../utils/Api";
import { DynamicOptionsSelect } from "../../utils/Utils";
import PhoneInputField from "./PhoneInputField";
import "./SignUp.css";

const goToSignIn = (navigate) => {
  //Luego de modificar dataSuccess, demoramos 5 segundos y redirigimos al login
  setTimeout(() => {
    navigate("/auth/sign-in");
  }, 5000);
};

const validatePhone = (e) => {
  console.log("validatePhone: ", e);
  // const phone = touched.phone;
  // let error;
  // if (!value) {
  //   error = 'Required';
  // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
  //   error = 'Invalid email address';
  // }

  // return error;
};

//Componente para el formulario de Cliente Individual
const IndividualClient = (state) => {
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const [createSuccess, setCreateSuccess] = useState(false);
  const [cities, setCities] = useState([]);

  const getCities = async (codCountry) => {
    const response = await citiesList(codCountry);
    setCities(response);
  };

  return (
    <Formik
      initialValues={{
        clientType: "Individual",
        tittle: "Mr",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        country: {},
        city: {},
        birthDate: "",
        address: "",
        zipCode: "",
        referralLinkId: "",
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255).required("First name is required"),
        lastName: Yup.string().max(255).required("Last name is required"),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        // phone: Yup.string().matches(
        //   /^[+][0-9]{10}/,
        //   "Phone number is not valid"
        // ),
        country: Yup.string().required("Country is required"),
        city: Yup.string().required("City is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const userData = {
            clientType: "Individual",
            tittle: values.tittle,
            phone: values.phone,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            country: values.country,
            city: values.city,
            birthDate: values.birthDate,
            address: values.address,
            zipCode: values.zipCode,
            referralLinkId: values.referralLinkId,
          };
          setSubmitting(true);
          await signUp(userData);
          setCreateSuccess(true);
          goToSignIn(navigate);
        } catch (error) {
          const message = error.message || "Something went wrong";
          console.log(error);
          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
          setCreateSuccess(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isInvalid,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit} className="tab-border">
          <Form.Group className="mb-3 col-sm-8 col-md-3">
            <Form.Label htmlFor="tittle">Client Tittle</Form.Label>
            <Form.Select
              name="tittle"
              id="tittle"
              onChange={handleChange}
              isInvalid={Boolean(touched.tittle && errors.tittle)}
              value={values.tittle}
            >
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Ms">Ms</option>
            </Form.Select>
          </Form.Group>
          <Row className="mb-3">
            <Col sm>
              <Form.Group>
                <Form.Label>First name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder=""
                  value={values.firstName}
                  isInvalid={Boolean(touched.firstName && errors.firstName)}
                  onChange={handleChange}
                />
                {!!touched.firstName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col sm>
              <Form.Group>
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder=""
                  value={values.lastName}
                  isInvalid={Boolean(touched.lastName && errors.lastName)}
                  onChange={handleChange}
                />
                {!!touched.lastName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm>
              <Form.Group>
                <Form.Label>Birthday</Form.Label>
                <Form.Control
                  type="date"
                  name="birthDate"
                  placeholder="dd-mm-aaaa"
                  value={values.birthDate}
                  isInvalid={Boolean(touched.birthDate && errors.birthDate)}
                  onChange={handleChange}
                />
                {!!touched.birthDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.birthDate}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col sm>&nbsp;</Col>
          </Row>
          <Row className="mb-3">
            <Col sm>
              {state.state.countries && (
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="country">Country</Form.Label>
                  <Form.Select
                    name="country"
                    id="country"
                    onChange={(e) => {
                      // call the built-in handleBur
                      handleChange(e);
                      getCities(e.target.value);
                    }}
                    isInvalid={Boolean(touched.country && errors.country)}
                    value={values.country}
                  >
                    <option value="">Select country</option>
                    <DynamicOptionsSelect
                      options={state.state.countries}
                      clave="code"
                      value="name"
                    />
                  </Form.Select>
                </Form.Group>
              )}
            </Col>
            <Col sm>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="city">City</Form.Label>
                <Form.Select
                  name="city"
                  id="city"
                  onChange={handleChange}
                  isInvalid={Boolean(touched.city && errors.city)}
                  value={values.city}
                >
                  <option value="">Select city</option>
                  <DynamicOptionsSelect
                    options={cities}
                    clave="name"
                    value="name"
                  />
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              placeholder=""
              value={values.address}
              isInvalid={Boolean(touched.address && errors.address)}
              onChange={handleChange}
            />
            {!!touched.address && (
              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              type="text"
              name="zipCode"
              placeholder=""
              value={values.zipCode}
              isInvalid={Boolean(touched.zipCode && errors.zipCode)}
              onChange={handleChange}
            />
            {!!touched.zipCode && (
              <Form.Control.Feedback type="invalid">
                {errors.zipCode}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone</Form.Label>
            <Field
              type="tel"
              name="phone"
              component={PhoneInputField}
              validate={validatePhone}
            />
            {!!touched.phone && (
              <Form.Control.Feedback type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder=""
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Row className="mb-3">
            <Col sm>
              <Form.Group className="mb-3">
                <Form.Label>IB Code</Form.Label>
                <Form.Control
                  type="number"
                  name="referralLinkId"
                  placeholder=""
                  value={values.referralLinkId}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col sm>&nbsp;</Col>
          </Row>
          {createSuccess && (
            <div>
              <AlertCustom
                variant="success"
                message="The user has been created."
              />
            </div>
          )}
          {errors.submit && (
            <Alert className="my-3" variant="warning">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              name="btnSubmit"
              disabled={isSubmitting}
            >
              Sign up
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

//Componente para el formulario de Cliente Corporativo
const CorporateClient = (state) => {
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const [createSuccess, setCreateSuccess] = useState(false);
  const [cities, setCities] = useState([]);

  const getCities = async (codCountry) => {
    const response = await citiesList(codCountry);
    setCities(response);
  };

  return (
    <Formik
      initialValues={{
        clientType: "Individual",
        firstName: "",
        phone: "",
        email: "",
        // password: "",
        // passwordConfirm: "",
        country: {},
        city: {},
        address: "",
        zipCode: "",
        referralLinkId: "",
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().max(255).required("First name is required"),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        phone: Yup.string().matches(
          /^[+][0-9]{10}/,
          "Phone number is not valid"
        ),
        // password: Yup.string()
        //   .min(8, "Must be at least 12 characters")
        //   .max(255)
        //   .required("Required"),
        // passwordConfirm: Yup.string()
        //   .min(8, "Must be at least 12 characters")
        //   .max(255)
        //   .required("Required")
        //   .oneOf([Yup.ref("password"), null], "Passwords must match"),
        country: Yup.string().required("Country is required"),
        city: Yup.string().required("City is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const userData = {
            clientType: "Individual",
            phone: values.phone,
            email: values.email,
            // password: values.password,
            firstName: values.firstName,
            country: values.country,
            city: values.city,
            address: values.address,
            zipCode: values.zipCode,
            referralLinkId: values.referralLinkId,
          };
          setSubmitting(true);
          await signUp(userData);
          setCreateSuccess(true);
          goToSignIn(navigate);
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
          setCreateSuccess(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isInvalid,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit} className="tab-border">
          <Form.Group className="mb-3">
            <Form.Label>Company name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              placeholder=""
              value={values.firstName}
              isInvalid={Boolean(touched.firstName && errors.firstName)}
              onChange={handleChange}
            />
            {!!touched.firstName && (
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Row className="mb-3">
            <Col sm>
              {state.state.countries && (
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="country">Country</Form.Label>
                  <Form.Select
                    name="country"
                    id="country"
                    onChange={(e) => {
                      // call the built-in handleBur
                      handleChange(e);
                      getCities(e.target.value);
                    }}
                    isInvalid={Boolean(touched.country && errors.country)}
                    value={values.country}
                  >
                    <option value="">Select country</option>
                    <DynamicOptionsSelect
                      options={state.state.countries}
                      clave="code"
                      value="name"
                    />
                  </Form.Select>
                </Form.Group>
              )}
            </Col>
            <Col sm>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="city">City</Form.Label>
                <Form.Select
                  name="city"
                  id="city"
                  onChange={handleChange}
                  isInvalid={Boolean(touched.city && errors.city)}
                  value={values.city}
                >
                  <option value="">Select city</option>
                  <DynamicOptionsSelect
                    options={cities}
                    clave="name"
                    value="name"
                  />
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              placeholder=""
              value={values.address}
              isInvalid={Boolean(touched.address && errors.address)}
              onChange={handleChange}
            />
            {!!touched.address && (
              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              type="text"
              name="zipCode"
              placeholder=""
              value={values.zipCode}
              isInvalid={Boolean(touched.zipCode && errors.zipCode)}
              onChange={handleChange}
            />
            {!!touched.zipCode && (
              <Form.Control.Feedback type="invalid">
                {errors.zipCode}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone</Form.Label>
            <Field
              type="tel"
              name="phone"
              component={PhoneInputField}
              validate={validatePhone}
            />
            {!!touched.phone && (
              <Form.Control.Feedback type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder=""
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>IB Code</Form.Label>
            <Form.Control
              type="number"
              name="referralLinkId"
              placeholder=""
              value={values.referralLinkId}
              onChange={handleChange}
            />
          </Form.Group>
          {createSuccess && (
            <div>
              <AlertCustom
                variant="success"
                message="The user has been created."
              />
            </div>
          )}
          {errors.submit && (
            <Alert className="my-3" variant="warning">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              name="btnSubmit"
              disabled={isSubmitting}
            >
              Sign up
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

function GetClientTypeComponent(props) {
  const clientType = props.state.clientType;
  switch (clientType) {
    case "Individual":
      return <IndividualClient state={props.state} />;
    case "Corporate":
      return <CorporateClient state={props.state} />;
    default:
      return "";
  }
}

//Estado inicial de las variables de estado del componente
const initialState = {
  clientType: "Individual",
  countries: [],
};

//Componente padre
function SignUp() {
  const [state, setState] = useState(initialState);

  //useEffect para controlar la carga de los paises
  useEffect(() => {
    const getCountries = async () => {
      const response = await countriesList();
      setState({
        ...state,
        countries: response.data,
      });
    };
    getCountries();
  }, []);

  return (
    <>
      <div>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="country">I want to register as</Form.Label>
          {/* <Form.Select
            name="docType"
            id="docType"
            onChange={(e) => {
              setState({
                ...state,
                clientType: e.target.value,
              });
            }}
          >
            <option value="">Select...</option>
            <option value="Individual">Individual</option>
            <option value="Corporate">Corporate</option>
          </Form.Select> */}
        </Form.Group>
        <Nav
          variant="tabs"
          defaultActiveKey="Individual"
          activeKey={state.clientType}
          onSelect={(evKey) => setState({ ...state, clientType: evKey })}
        >
          <Nav.Item>
            <Nav.Link eventKey="Individual">Individual</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Corporate">Corporate</Nav.Link>
          </Nav.Item>
        </Nav>
        {state.clientType && <GetClientTypeComponent state={state} />}
      </div>
      <div className="text-center mt-3">
        Do you already have an account? <Link to="/auth/sign-in">Sign In</Link>
      </div>
    </>
  );
}

export default SignUp;
