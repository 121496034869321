import React, { useState, useEffect } from "react";

import { Card, Dropdown, Table } from "react-bootstrap";

import useAuth from "../../../hooks/useAuth";
import { getAccounts, AccountType } from "../../../utils/Api";

import { MoreHorizontal } from "react-feather";

const Projects = () => {
  const [cuentas, setCuentas] = useState(null);
  const { user } = useAuth();
  // useEffect(() => {
  //   const cuentas = {
  //     wallet: null,
  //     live: [],
  //     demo: [],
  //   };
  //   const accountsApi = {};
  //   const consultType = async (category) => {
  //     const response = await AccountType();
  //     const typesAccount = response.data;
  //     typesAccount.forEach((cuenta) => {
  //       accountsApi[cuenta.id] = cuenta;
  //     });
  //     return accountsApi;
  //   };
  //   const ConsultarCuentas = async () => {
  //     // const { user } = useAuth();
  //     // console.log(user);
  //     const types = await consultType();
  //     const res = await getAccounts(user.id);
  //     const cts = res.data;
  //     cts.forEach((cuenta) => {
  //       if (cuenta.accountTypeId === 1) cuentas.wallet = cuenta;
  //       else if (cuenta.accountTypeId === 10) cuentas.demo.push(cuenta);
  //       else cuentas.live.push(cuenta);
  //     });
  //     setCuentas(cuentas);
  //   };

  //   if (user) ConsultarCuentas();
  // }, [user]);

  return (
    <>
      <Card className="flex-fill w-100">
        <Card.Header>
          <div className="card-actions float-end">
            <Dropdown align="end">
              <Dropdown.Toggle as="a" bsPrefix="-">
                <MoreHorizontal />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>Action</Dropdown.Item>
                <Dropdown.Item>Another Action</Dropdown.Item>
                <Dropdown.Item>Something else here</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Card.Title tag="h5" className="mb-0">
            Cuenta Wallet
          </Card.Title>
        </Card.Header>
        <Table striped className="my-0">
          <thead>
            <tr>
              <th>Tipo</th>
              <th className="d-none d-xl-table-cell">Leverange</th>
              <th className="d-none d-xl-table-cell">Balance</th>
              <th>Credit</th>
              <th className="d-none d-md-table-cell">Fecha de Creacion</th>
            </tr>
          </thead>
          <tbody>
            {cuentas?.wallet && (
              <tr>
                <td>{cuentas.wallet.groupName}</td>
                <td>1:{cuentas.wallet.leverage}</td>
                <td>{cuentas.wallet.balance}</td>
                <td>{cuentas.wallet.credit}</td>
                <td>{cuentas.wallet.createdAt}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
      <Card className="flex-fill w-100">
        <Card.Header>
          <div className="card-actions float-end">
            <Dropdown align="end">
              <Dropdown.Toggle as="a" bsPrefix="-">
                <MoreHorizontal />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>Action</Dropdown.Item>
                <Dropdown.Item>Another Action</Dropdown.Item>
                <Dropdown.Item>Something else here</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Card.Title tag="h5" className="mb-0">
            Cuentas Live
          </Card.Title>
        </Card.Header>
        <Table striped className="my-0">
          <thead>
            <tr>
              <th>Tipo</th>
              <th className="d-none d-xl-table-cell">Leverange</th>
              <th className="d-none d-xl-table-cell">Balance</th>
              <th>Credit</th>
              <th className="d-none d-md-table-cell">Fecha de Creacion</th>
            </tr>
          </thead>
          <tbody>
            {cuentas?.live &&
              cuentas.live.map((cuenta, i) => (
                <tr key={i}>
                  <td>{cuenta.groupName}</td>
                  <td>1:{cuenta.leverage}</td>
                  <td>{cuenta.balance}</td>
                  <td>{cuenta.credit}</td>
                  <td>{cuenta.createdAt}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card>
      <Card className="flex-fill w-100">
        <Card.Header>
          <div className="card-actions float-end">
            <Dropdown align="end">
              <Dropdown.Toggle as="a" bsPrefix="-">
                <MoreHorizontal />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>Action</Dropdown.Item>
                <Dropdown.Item>Another Action</Dropdown.Item>
                <Dropdown.Item>Something else here</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Card.Title tag="h7" className="mb-0">
            Cuentas Demo
          </Card.Title>
        </Card.Header>
        <Table striped className="my-0">
          <thead>
            <tr>
              <th>Tipo</th>
              <th className="d-none d-xl-table-cell">Leverange</th>
              <th className="d-none d-xl-table-cell">Balance</th>
              <th>Credit</th>
              <th className="d-none d-md-table-cell">Fecha de Creacion</th>
            </tr>
          </thead>
          <tbody>
            {cuentas?.demo &&
              cuentas.demo.map((cuenta, i) => (
                <tr key={i}>
                  <td>{cuenta.groupName}</td>
                  <td>1:{cuenta.leverage}</td>
                  <td>{cuenta.balance}</td>
                  <td>{cuenta.credit}</td>
                  <td>{cuenta.createdAt}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default Projects;
