import React from "react";
import { Helmet } from "react-helmet-async";

import { Badge, Card, Col, Container, Row, Table } from "react-bootstrap";

const ProfileInformation = () => (
  <Card>
    <Card.Header>
      <Card.Title tag="h5" className="mb-0">
        Profile Information
      </Card.Title>
    </Card.Header>
    <Card.Body>
      <Table className="mb-0">
        <thead>
          <tr>
            <th>#</th>
            <th>Document</th>
            <th>Created at</th>
            <th>Version</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {/* <tr>
            <td></td>
            <td>Angelica Ramos</td>
            <td>The Wiz</td>
            <td>angelica@ramos.com</td>
            <td>
              <Badge bg="success">Active</Badge>
            </td>
          </tr> */}
          <tr>
            <td colSpan="4">There are no accepted documents</td>
          </tr>
        </tbody>
      </Table>
    </Card.Body>
  </Card>
);

const CompanyDocuments = () => (
  <Card>
    <Card.Header>
      <Card.Title tag="h5" className="mb-0">
        Company Documents
      </Card.Title>
    </Card.Header>
    <Card.Body>
      <Table className="mb-0">
        <thead>
          <tr>
            <th>Document</th>
            <th>Created at</th>
            <th>Version</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <a
                href="https://client.castlerockfx.com/uploads/public/company-documents/2021/08/25/214d9b8c8fb4dadca24b8a0fd7ee1078.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <i class="file-earmark-pdf-fill">Terms & Agreement of Use</i>
              </a>
            </td>
            <td>25-08-2021 14:17</td>
            <td>v1</td>
          </tr>
        </tbody>
      </Table>
    </Card.Body>
  </Card>
);

const Agreements = () => (
  <React.Fragment>
    <Helmet title="Agreements" />
    <Container fluid className="p-0">
      <h1 className="h3 mb-3">My Agreements</h1>

      <Row>
        <Col md="6" xl="6">
          <ProfileInformation />
        </Col>
        <Col md="6" xl="6">
          <CompanyDocuments />
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default Agreements;
