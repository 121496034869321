import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  getInvestor,
  getInvestors,
  getPammMasters,
  getOrdersCloses,
} from "../../../utils/ApiPamm";
import { queryParams } from "../../../utils/Utils";
import { getAccounts } from "../../../utils/Api";
import useAuth from "../../../hooks/useAuth";
import PammDashboardCard from "./PammDashboardCard/PammDashboardCard";

export default function PammDashboard() {
  const { user } = useAuth();
  const [state, setState] = useState({ masters: [] });

  const card_data = {
    name: "Person",
    id: 1000,
    perf: 10,
    total: 0.04,
    man: 10,
    pnl: 0,
    ann_man: 0,
    min_inv: 100,
    per_lot: 0,
    per_deal: 0,
    investors: 2,
    values: [1, 3, 5, 2, 32, -12, 32, 2],
  };

  useEffect(() => {
    const AccountsConsultInvestors = async (id) => {
      const pammInv = [];
      // const investorsIds = {};

      const response = await getAccounts(id);
      const cuentas = response.data;

      cuentas.forEach((cuenta) => {
        if (cuenta.groupName === "CR-PAMM-INV-USD") pammInv.push(cuenta.login);
      });

      const _masters = await getPammMasters(
        "?page=1&hideNegativeProfitMasters=false&limit=30&sortField=Id&direction=asc"
      );

      let masters = [];

      if (_masters && _masters.data.Data && _masters.data.Data.length > 0) {
        _masters.data.Data.forEach(async (master) => {
          masters.push({
            name: master.Name,
            id: master.Id,
            perf: master.IncentiveAccounts[0].IncentiveFee,
            total: master.TotalProfit,
            man: master.IncentiveAccounts[0].ManagementFee,
            pnl: master.Profit,
            ann_man: master.IncentiveAccounts[0].IncentiveFee,
            min_inv: master.MinInvestment,
            per_lot: master.IncentiveAccounts[0].PerLotFee,
            per_deal: master.IncentiveAccounts[0].PerDealFee,
            investors: master.InvestorsCount,
            values: [],
          });
        });
      }
      setState({ masters: [...masters] });
    };
    if (user) {
      AccountsConsultInvestors(user.id);
      // AccountsConsultInvestors(1055); // test id
    }
  }, [user]);

  if (state.masters.length === 0) return <i />; // Here must be loading spinner

  return (
    <Container fluid>
      <Row>
        {state.masters &&
          state.masters.map((mst) => (
            <Col key={mst.id} sm="6" md="4" xxl="3">
              <PammDashboardCard key={mst.id} data={mst} />
            </Col>
          ))}
      </Row>
    </Container>
  );
}
