import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import {
  Container,
  Spinner,
  Row,
  Col,
  Card,
  Button,
  Nav,
  Tab,
} from "react-bootstrap";
import useAuth from "../../../hooks/useAuth";
import { getAccounts } from "../../../utils/Api";
import {
  getInvestor,
  getInvestors,
  getOrdersCloses,
} from "../../../utils/ApiPamm";
import ColumnSortingPagintaionCustom from "../../tables/ColumnSortingPagintaionCustom";
import InvestorInfo from "./InvestorInfo";
import { queryParams } from "../../../utils/Utils";
import Line from "../../charts/Chartjs/Line";
import ProfitLineChart from "./ProfitLineChart/ProfitLineChart";
import styles from "./css/MyInvestment.module.css";
import { DollarSign } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import ClosedOrdersTable from "./ClosedOrdersTable/ClosedOrdersTable";

// const OrdersCloses = ({ investor }) => {
//   const tableColumns = useMemo(
//     () => [
//       {
//         Header: "PAMM",
//         accessor: "MasterId",
//       },
//       {
//         Header: "Order",
//         accessor: "MtOrderId",
//       },
//       {
//         Header: "Symbol",
//         accessor: "Symbol",
//       },
//       {
//         Header: "Type",
//         accessor: "Direction",
//       },
//       {
//         Header: "Volume",
//         accessor: "Volume",
//       },
//       {
//         Header: "Open Time",
//         accessor: "OpenTime",
//       },
//       {
//         Header: "Open Price",
//         accessor: "OpenPrice",
//       },
//       {
//         Header: "Close Time",
//         accessor: "CloseTime",
//       },
//       {
//         Header: "Close Price",
//         accessor: "ClosePrice",
//       },
//       {
//         Header: "PNL",
//         accessor: "Profit",
//       },
//     ],
//     []
//   );

//   const [dataOrders, setdataOrders] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [pageCount, setPageCount] = useState(0);
//   const [showOrders, setShowOrders] = useState(false);
//   const fetchDataCalback = useCallback(
//     async ({ pageSize, pageIndex, skipPageResetRef }) => {
//       setLoading(true);
//       const filter = {
//         page: pageIndex + 1,
//         limit: pageSize,
//       };

//       const contents = await getOrdersCloses(investor.Id, queryParams(filter));
//       const orders = contents.data;
//       skipPageResetRef.current = true;
//       console.log(orders);
//       setdataOrders(orders.Data);
//       setPageCount(orders.PaginationInfo.TotalPages);
//       setLoading(false);
//     },
//     []
//   );
//   const handleShowOrders = () => {
//     console.log(showOrders);
//     setShowOrders(!showOrders);
//   };

//   return (
//     <>
//       <InvestorInfo investor={investor} handleShowOrders={handleShowOrders} />
//       {showOrders && (
//         <ColumnSortingPagintaionCustom
//           columns={tableColumns}
//           data={dataOrders}
//           fetchData={fetchDataCalback}
//           pageCount={pageCount}
//           loading={loading}
//         />
//       )}
//     </>
//   );
// };

const MyInvestment = () => {
  const [investors, setInvestors] = useState(null);
  const [ordersClosesData, setOrdersClosesData] = useState();

  const { user } = useAuth();
  const [userInvestorInfo, setUserInvestorInfo] = useState(null);

  useEffect(() => {
    const AccountsConsultInvestors = async (id) => {
      const pammInv = [];
      const investorsIds = {};

      const response = await getAccounts(id);
      const cuentas = response.data;
      console.log({ cuentas });
      cuentas.forEach((cuenta) => {
        if (cuenta.groupName === "CR-PAMM-INV-USD") pammInv.push(cuenta.login);
      });
      const resInv = await getInvestors(queryParams({ logins: pammInv }));
      console.log({ resInv });
      const investors = resInv.data.Data;
      console.log({ investors });
      console.log({ user });
      const currUserInvestor = investors.find(
        (item) => item.Email === user.email
      );
      console.log({ currUserInvestor });
      // const ordersCloses = await getOrdersCloses(currUserInvestor.Id);
      const ordersCloses = await getOrdersCloses("100055");
      console.log({ ordersCloses });
      setOrdersClosesData(ordersCloses.data.Data);
      investors.forEach((investor) => {
        if (investor.MasterId > 0) {
          investorsIds[investor.Id] = investor;
        }
      });
      setInvestors(investorsIds);
      if (currUserInvestor) setUserInvestorInfo(currUserInvestor);
    };

    if (user) {
      // AccountsConsultInvestors(user.id);
      AccountsConsultInvestors(1055);
    }
  }, [user]);

  const onDeposit = () => {
    console.log("Deposit pressed");
  };

  const onWithdraw = () => {
    console.log("Withdraw pressed");
  };

  return (
    <React.Fragment>
      <Helmet title="My Investments" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3"> My investments</h1>
        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body className="d-flex align-items-center">
                <span className={`${styles.descBalance} me-5`}>
                  <span className="me-1">
                    {userInvestorInfo
                      ? userInvestorInfo.Investments.toFixed(2)
                      : ""}
                  </span>
                  <FontAwesomeIcon icon={faDollarSign} size="lg" />
                </span>
                <div className={`${styles.descInvestorInfo}`}>
                  <span className={styles.descInvestorPropertyName}>
                    Investments:
                  </span>
                  <span className={styles.descInvestorProperty}>
                    {userInvestorInfo
                      ? userInvestorInfo.Investments.toFixed(2)
                      : ""}
                  </span>
                </div>
                <div className={styles.descInvestorInfo}>
                  <span className={styles.descInvestorPropertyName}>
                    Balance:
                  </span>
                  <span className={styles.descInvestorProperty}>
                    {userInvestorInfo
                      ? userInvestorInfo.Balance.toFixed(2)
                      : ""}
                  </span>
                </div>
                <div className={styles.descInvestorInfo}>
                  <span className={styles.descInvestorPropertyName}>
                    Credit:
                  </span>
                  <span className={styles.descInvestorProperty}>
                    {userInvestorInfo ? userInvestorInfo.Credit : ""}
                  </span>
                </div>
                <div className={styles.descInvestorInfo}>
                  <span className={styles.descInvestorPropertyName}>SL:</span>
                  <span className={styles.descInvestorProperty}>
                    {userInvestorInfo ? userInvestorInfo.Sl : ""}
                  </span>
                </div>
                <div className={styles.descInvestorInfo}>
                  <span className={styles.descInvestorPropertyName}>TP:</span>
                  <span className={styles.descInvestorProperty}>
                    {userInvestorInfo ? userInvestorInfo.Tp : ""}
                  </span>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={7} lg={8} xxl={9}>
            <ProfitLineChart ordersClosesData={ordersClosesData} />
          </Col>
          <Col sm={12} md={5} lg={4} xxl={3}>
            <Row>
              <Col sm={12} lg={12}>
                <div className={styles.accountButtons}>
                  <button className="btn btn-primary" onClick={onDeposit}>
                    Deposit
                  </button>
                  <button className="btn btn-primary" onClick={onWithdraw}>
                    Withdraw
                  </button>
                </div>
              </Col>
              <Col sm={6} md={12}>
                <Card className="flex-fill">
                  <Card.Body className="text-center">
                    <div className={styles.accountDescTitle}>
                      <span>Account Info</span>
                    </div>
                    <ul className={styles.accountDescUl}>
                      <li className={styles.accountDescRow}>
                        <div>
                          <span className={styles.descItemName}>
                            Total Profit
                          </span>
                        </div>
                        <span className={styles.descItemValue}>
                          {userInvestorInfo?.TotalProfit
                            ? userInvestorInfo.TotalProfit
                            : "0"}
                        </span>
                      </li>
                      <li className={styles.accountDescRow}>
                        <div>
                          <span className={styles.descItemName}>
                            High Water Mark
                          </span>
                        </div>
                        <span className={styles.descItemValue}>
                          {userInvestorInfo?.HighWaterMark
                            ? userInvestorInfo.HighWaterMark
                            : "0"}
                        </span>
                      </li>
                      <li className={styles.accountDescRow}>
                        <div>
                          <span className={styles.descItemName}>
                            Available Free Margin
                          </span>
                        </div>
                        <span className={styles.descItemValue}>
                          {userInvestorInfo?.AvailableFreeMarginNoCredit
                            ? userInvestorInfo.AvailableFreeMarginNoCredit
                            : "0"}
                        </span>
                      </li>
                      <li className={styles.accountDescRow}>
                        <div>
                          <span className={styles.descItemName}>PAMM ID</span>
                        </div>
                        <span className={styles.descItemValue}>
                          {userInvestorInfo?.Id ? userInvestorInfo.Id : ""}
                        </span>
                      </li>
                      <li className={styles.accountDescRow}>
                        <div>
                          <span className={styles.descItemName}>MT Login</span>
                        </div>
                        <span className={styles.descItemValue}>
                          {userInvestorInfo?.Login
                            ? userInvestorInfo.Login
                            : ""}
                        </span>
                      </li>
                      <li className={styles.accountDescRow}>
                        <div>
                          <span className={styles.descItemName}>MT Server</span>
                        </div>
                        <span className={styles.descItemValue}>
                          {userInvestorInfo?.MtServerType
                            ? userInvestorInfo.MtServerType
                            : ""}
                        </span>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={6} md={12}>
                <Card className="flex-fill">
                  <Card.Body className="text-center">
                    <div className={styles.accountDescTitle}>
                      <span>Commisions Info</span>
                    </div>
                    <ul className={styles.accountDescUl}>
                      <li className={styles.accountDescRow}>
                        <div>
                          <span className={styles.descItemName}>
                            Performance Fee
                          </span>
                        </div>
                        <span className={styles.descItemValue}></span>
                      </li>
                      <li className={styles.accountDescRow}>
                        <div>
                          <span className={styles.descItemName}>
                            Performance Fee Charge Periods
                          </span>
                        </div>
                        <span className={styles.descItemValue}></span>
                      </li>
                      <li className={styles.accountDescRow}>
                        <div>
                          <span className={styles.descItemName}>
                            Management Fee
                          </span>
                        </div>
                        <span className={styles.descItemValue}>
                          {userInvestorInfo?.IncentiveSummary.ManagementFee
                            ? userInvestorInfo?.IncentiveSummary.ManagementFee
                            : "0"}
                        </span>
                      </li>
                      <li className={styles.accountDescRow}>
                        <div>
                          <span className={styles.descItemName}>
                            Annual Management Fee
                          </span>
                        </div>
                        <span className={styles.descItemValue}>
                          {
                            userInvestorInfo?.IncentiveSummary.AnnualManagementFee ? userInvestorInfo?.IncentiveSummary.AnnualManagementFee : "0"
                          }
                        </span>
                      </li>
                      <li className={styles.accountDescRow}>
                        <div>
                          <span className={styles.descItemName}>
                            Per Lot Fee
                          </span>
                        </div>
                        <span className={styles.descItemValue}>
                          {userInvestorInfo?.IncentiveSummary.PerLotFee
                            ? userInvestorInfo?.IncentiveSummary.PerLotFee
                            : "0"}
                        </span>
                      </li>
                      <li className={styles.accountDescRow}>
                        <div>
                          <span className={styles.descItemName}>
                            Per Deal Fee
                          </span>
                        </div>
                        <span className={styles.descItemValue}>
                          {userInvestorInfo?.IncentiveSummary.PerDealFee
                            ? userInvestorInfo?.IncentiveSummary.PerDealFee
                            : "0"}
                        </span>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            {!!investors &&
              Object.values(investors).map((investor, i) => (
                <InvestorInfo investor={investor} key={investor.Id} />
              ))}
            {!investors && (
              <div className="mx-auto">
                <Spinner
                  animation="border"
                  variant="primary"
                  className="me-2"
                />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col>
                  <Card>
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="first">CLOSED ORDERS</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          BALANCE OPERATIONS
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <ClosedOrdersTable ClosedOrders={ordersClosesData} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second"></Tab.Pane>
                    </Tab.Content>
                  </Card>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default MyInvestment;
