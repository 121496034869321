import {
  /*
  Bell,
  BookOpen,
  CheckSquare,
  Grid,
  Heart,
  Layout,
  MapPin,
  PieChart,
  Sliders,
  Share,
  TrendingUp,
  Briefcase,
  BarChart2,
  */
  List,
  Users,
  CreditCard,
  Download,
  AlignJustify,
  Archive,
} from "react-feather";

const tradersSection = [
  {
    href: "/auth",
    icon: Users,
    title: "Profile",
    children: [
      /*
      {
        href: "/auth/reset-password",
        title: "Reset Password",
      },
      {
        href: "/pages/profile",
        title: "Profile",
      },
      */
      {
        href: "/pages/settings",
        title: "Profile",
      },
      {
        href: "/pages/uploadDocuments",
        title: "Upload Documents",
      },
      {
        href: "/pages/my-agreements",
        title: "My agreements",
      },
    ],
  },
  {
    href: "/pages/download",
    icon: Download,
    title: "Download",
  },
  {
    href: "/dashboard",
    icon: List,
    title: "Accounts",
    badge: "5",
    children: [
      {
        href: "/forms/account-create-live",
        title: "Open Live Account",
      },
      {
        href: "/pages/accounts-overview",
        title: "Accounts Overview",
      },
    ],
  },
  {
    href: "/pages",
    icon: CreditCard,
    title: "Funds",
    children: [
      {
        href: "/pages/transfer-funds",
        title: "Transfer Funds",
      },
      {
        href: "/pages/transaction-history",
        title: "Transaction History",
      },
    ],
  },
];

/*
const ibSection = [
  {
    href: "/ui",
    icon: TrendingUp,
    title: "Dashbnoard",
  },
  {
    href: "/icons",
    icon: PieChart,
    title: "Performance Dashboard",
  },
  {
    href: "/forms",
    icon: Sliders,
    title: "Commission Settings",
  },
  {
    href: "/tables",
    icon: Briefcase,
    title: "Marketing Tool",
    children: [
      {
        href: "/form-plugins/advanced-inputs",
        title: "Advanced Inputs",
      },
      {
        href: "/form-plugins/formik",
        title: "Formik",
        badge: "New",
      },
      {
        href: "/form-plugins/editors",
        title: "Editors",
      },
    ],
  },
  {
    href: "/tables",
    icon: BarChart2,
    title: "Reports",
    children: [
      {
        href: "/form-plugins/advanced-inputs",
        title: "Advanced Inputs",
      },
      {
        href: "/form-plugins/formik",
        title: "Formik",
        badge: "New",
      },
      {
        href: "/form-plugins/editors",
        title: "Editors",
      },
    ],
  },
];
*/
const pammSection = [
  {
    href: "/pages/pamm-accounts",
    icon: AlignJustify,
    title: "PAMM Accounts",
  },
  {
    href: "/pages/dashboard",
    icon: AlignJustify,
    title: "Perfomance Dashboard",
  },
  {
    href: "/pages/my-investment",
    icon: Archive,
    title: "My Investments",
  },
  {
    href: "/forms/account-create-pamm",
    icon: Archive,
    title: "Create Money Manager",
  },
  {
    href: "/forms/account-create-investor",
    icon: Archive,
    title: "Create Investor",
  },
  /*
  {
    href: "/charts",
    icon: Archive,
    title: "My Financial Request",
  },
  {
    href: "/notifications",
    icon: List,
    title: "My Managed Accounts",
  },
  {
    href: "/maps",
    icon: Briefcase,
    title: "Become PAMM Managed",
    children: [
      {
        href: "/maps/google-maps",
        title: "Google Maps",
      },
      {
        href: "/maps/vector-maps",
        title: "Vector Maps",
      },
    ],
  },
  {
    href: "/calendar",
    icon: Calendar,
    title: "Calendar",
  },
  {
    href: "/404",
    icon: Share,
    title: "Multi Level",
    children: [
      {
        href: "/404",
        title: "Two Levels",
        children: [
          {
            href: "/404",
            title: "Item 1",
          },
          {
            href: "/404",
            title: "Item 2",
          },
        ],
      },
      {
        href: "/404",
        title: "Three Levels",
        children: [
          {
            href: "/404",
            title: "Item 1",
            children: [
              {
                href: "/404",
                title: "Item 1",
              },
              {
                href: "/404",
                title: "Item 2",
              },
            ],
          },
          {
            href: "/404",
            title: "Item 2",
          },
        ],
      },
    ],
  },
  */
];

const navItems = [
  {
    title: "CLIENT'S MENU",
    pages: tradersSection,
  },
  {
    title: "PAMM",
    pages: pammSection,
  },
];

export default navItems;
