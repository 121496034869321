import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faUpload } from "@fortawesome/free-solid-svg-icons";

import avatar_M from "../../../assets/img/avatars/avatar_M.png";
import avatar_F from "../../../assets/img/avatars/avatar_F.png";
import useAuth from "../../../hooks/useAuth";
import { countriesList, updateUserData } from "../../../utils/Api";
import {
  DynamicOptionsSelect,
  formatStrDateDDMMYYYYToYYYYMMDD,
  formatStrDateYYYYMMDDToDDMMYYYY,
} from "../../../utils/Utils";

import * as Yup from "yup";
import { Formik } from "formik";

const Navigation = () => (
  <Card>
    <Card.Header>
      <Card.Title tag="h5" className="mb-0">
        Profile Settings
      </Card.Title>
    </Card.Header>
    <ListGroup variant="flush">
      <ListGroup.Item tag="a" href="#" action active>
        Account
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#" action>
        Password
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#" action>
        Privacy and safety
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#" action>
        Email notifications
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#" action>
        Web notifications
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#" action>
        Widgets
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#" action>
        Your data
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#" action>
        Delete account{" "}
      </ListGroup.Item>
    </ListGroup>
  </Card>
);

const PublicInfo = ({ user }) => {
  const imgProfile =
    user && (user.title == null || user.title === "Mr") ? avatar_M : avatar_F;

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0"></Card.Title>
      </Card.Header>
      <Card.Body>
        <Form>
          <Row>
            <Col>
              <Card>
                <Card.Body className="text-center">
                  <img
                    src={imgProfile}
                    alt="Stacie Hall"
                    className="rounded-circle img-responsive mt-2"
                    width="128"
                    height="128"
                  />
                  <Card.Title tag="h5" className="mb-0">
                    {user && (
                      <span>{user.firstName + " " + user.lastName}</span>
                    )}
                  </Card.Title>
                  <div className="text-muted mb-2">
                    User ID: {user && <span>{user.id}</span>}
                  </div>

                  {/* <div className="text-center">
                    <div className="mt-2">
                      <Button variant="primary">
                        <FontAwesomeIcon icon={faUpload} /> Upload
                      </Button>
                    </div>
                    <small>
                      For best results, use an image at least 128px by 128px in
                      .jpg format
                    </small>
                  </div> */}
                </Card.Body>

                <hr className="my-0" />
              </Card>
            </Col>
          </Row>

          {/* <Button variant="primary" className="">
            Save changes
          </Button> */}
        </Form>
      </Card.Body>
    </Card>
  );
};

const schema = () => {
  let sch = {
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    phone: Yup.string().required("Phone is required"),
  };
  return Yup.object().shape(sch);
};

const initialValues = (user) => {
  let iv = {
    firstName: user && user.firstName ? user.firstName : "",
    lastName: user && user.lastName ? user.lastName : "",
    email: user && user.email ? user.email : "",
    phone: user && user.phone ? user.phone : "",
    country: user && user.country ? user.country : "",
    zipCode: user && user.zipCode ? user.zipCode : "",
    address: user && user.address ? user.address : "",
    birthDate:
      user && user.birthDate
        ? formatStrDateDDMMYYYYToYYYYMMDD(user.birthDate)
        : "",
  };
  return iv;
};

const PrivateInfo = ({ user, countries }) => {
  const [dataSuccess, setDataSuccess] = useState(null);

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0"></Card.Title>
      </Card.Header>
      <Card.Body>
        <Formik
          validationSchema={schema()}
          initialValues={initialValues(user)}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const userData = initialValues(user);
              setSubmitting(true);
              const response = await updateUserData({
                ...userData,
                ...values,
                birthDate: formatStrDateYYYYMMDDToDDMMYYYY(values.birthDate),
                user: user.id,
              });
              //setUser(response.data);
              setDataSuccess(response.data);
            } catch (error) {
              const message = error.message || "Something went wrong";

              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="firstName">First name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={Boolean(touched.firstName && errors.firstName)}
                    />
                    {!!touched.firstName && (
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="lastName">Last name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      id="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={Boolean(touched.lastName && errors.lastName)}
                    />
                    {!!touched.lastName && (
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="email">Email</Form.Label>
                    <Form.Control
                      size="lg"
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={Boolean(touched.email && errors.email)}
                      readOnly
                    />
                    {!!touched.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="phone">Phone</Form.Label>
                    <Form.Control
                      type="phone"
                      name="phone"
                      id="phone"
                      onChange={handleChange}
                      isInvalid={Boolean(touched.phone && errors.phone)}
                      value={values.phone}
                    />
                    {!!touched.phone && (
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="address">Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      id="address"
                      onChange={handleChange}
                      isInvalid={Boolean(touched.address && errors.address)}
                      value={values.address}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="birthDate">Birth Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder=""
                      name="birthDate"
                      id="birthDate"
                      onChange={handleChange}
                      isInvalid={Boolean(touched.birthDate && errors.birthDate)}
                      value={values.birthDate}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="city">City</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      id="city"
                      onChange={handleChange}
                      isInvalid={Boolean(touched.city && errors.city)}
                      value={user && user.city}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="zipCode">Zip Code</Form.Label>
                    <Form.Control
                      type="text"
                      name="zipCode"
                      id="zipcode"
                      onChange={handleChange}
                      isInvalid={Boolean(touched.zipCode && errors.zipCode)}
                      value={values.zipCode}
                    />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="country">Country</Form.Label>
                    <Form.Select
                      name="country"
                      id="country"
                      onChange={handleChange}
                      isInvalid={Boolean(touched.country && errors.country)}
                      value={values.country}
                    >
                      <DynamicOptionsSelect
                        options={countries}
                        clave="code"
                        value="name"
                      />
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              {!!dataSuccess && (
                <Row>
                  <div>
                    <Alert className="mb-3" variant="success">
                      <div className="alert-message">
                        Your user data has been updated.
                      </div>
                    </Alert>
                  </div>
                </Row>
              )}

              <Button
                type="submit"
                variant="primary"
                size="lg"
                //disabled={isSubmitting}
              >
                Save changes
              </Button>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

const Settings = () => {
  const { user } = useAuth();
  const [usr, setUsr] = useState(false);
  const [countries, setCountries] = useState(null);

  //useEffect para controlar el cambio del user
  useEffect(() => {
    if (user) setUsr(true);
  }, [user]);

  //useEffect para controlar la carga de los paises
  useEffect(() => {
    const getCountries = async () => {
      const response = await countriesList();
      setCountries(response.data);
    };
    getCountries();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Settings" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Profile Settings</h1>

        <Row>
          {/* <Col md="3" xl="2">
          <Navigation />
        </Col> */}
          <Col md="9" xl="10">
            {/* {usr && <PublicInfo user={user} />} */}
            {usr && countries && (
              <PrivateInfo user={user} countries={countries} />
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default Settings;
