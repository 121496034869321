import React from "react";

import { Card, Col, Row, Table, Alert } from "react-bootstrap";

const ConfirmCreateAccount = (props) => (
  <Row className="justify-content-center">
    <Col xs="12" lg="8">
      {props.errors.submit && (
        <Alert className="my-3" variant="danger">
          <div className="alert-message">{props.errors.submit}</div>
        </Alert>
      )}
      <Card className="flex-fill w-100">
        <Card.Header>
          <Card.Title tag="h5" className="mb-0">
            Confirmation
          </Card.Title>
        </Card.Header>
        <Table striped className="my-0">
          <tbody>
            <tr>
              <th scope="row">TYPE</th>
              <td>{props.type}</td>
            </tr>
            <tr>
              <th scope="row">Leverage</th>
              <td>1:{props.values.leverage}</td>
            </tr>
            <tr>
              <th scope="row">Currency</th>
              <td>{props.values.currency}</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    </Col>
  </Row>
);

export default ConfirmCreateAccount;
