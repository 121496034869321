const AccountMoneyManagerPamm = {
  AllocationMode: 1,
  MinInvestment: 0,
  Name: "",
  Username: "",
  Email: "",
  Private: true,
  Invisible: false,
  IncentiveAccounts: [
    {
      PerSelect: "PerLotFee",
      PerLotDealFee: 0,
      PerLotFee: 0,
      PerDealFee: 0,
      Login: 0,
      IncentiveFee: 0,
      ManagementFee: 0,
      AnnualManagementFee: 0,
      Mode: "ByCurrentBalance",
    },
  ],
  Server: "CastleRockFX-Live",
  Login: 0,
  Currency: "USD",
  Comment: "",
};
export default AccountMoneyManagerPamm;
